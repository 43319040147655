import axios from 'axios';
import IBusinessArticle from '../Database/Model/BusinessArticles';
import IResponseError from '../Type/IResponseError';

const scheduleAtricleSending = async (
  id: string,
  article: IBusinessArticle,
  onSuccess: () => void,
  onError: (response: IResponseError) => void
) => {
  try {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 3);

    const axiosResponse = await axios.post(
      `${
        process.env.REACT_APP_API_URL as string
      }/businesses/${id}/schedule_article_sending` as string,
      {
        businessArticle: article['@id'],
        time: date.toISOString(),
      }
    );

    if (axiosResponse.status < 200 || axiosResponse.status >= 300) {
      throw new Error();
    }

    onSuccess();
  } catch (error) {
    onError({ error: 'Unable to send the article to wordpress!' });
  }
};

export default scheduleAtricleSending;
