import { Routes, Route } from 'react-router-dom';

import 'assets/css/Plugins.css';
import { useAuthContext } from 'components/auth/AuthProvider';
import SignIn from 'views/auth/signIn/SignIn';
import SignUp from 'views/auth/signUp/SignUp';
import SignUpSuccess from 'views/auth/signUp/SignUpSuccess';
import VerificationEmail from 'views/auth/verification/VerificationEmail';
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword';
import ResetPassword from 'views/auth/forgotPassword/ResetPassword';
import RefreshToken from 'components/auth/RefreshToken';
import ScrollToTop from './components/scrollToTop';
import EmptyEditorPreloader from 'components/EmptyEditorPreloader';
import ThemedLayout from 'components/dev/ThemedLayout';
import LoginAsUser from 'views/auth/loginAsUser/LoginAsUser';
import { SelectedVideoProvider } from 'views/main/dashboards/default/components/Video/SelectedVideoContext';
import CompleteRegistration from './views/auth/completeRegistration/CompleteRegistration';
import { TermsOfUse } from './views/main/dashboards/default/TermsOfUse';

const App = () => {
  const authContext = useAuthContext();

  return (
    <RefreshToken>
      <SelectedVideoProvider>
        <EmptyEditorPreloader />
        <div className="mx-auto max-w-[1320px]">
          {authContext.isAuthorized() ? (
            <>
              <ScrollToTop />
              <ThemedLayout showMainLayout={true} />
            </>
          ) : (
            <>
              <Routes>
                <Route path="/*" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signup/success" element={<SignUpSuccess />} />
                <Route
                  path="/registration/manager/:email*"
                  element={<CompleteRegistration />}
                />
                <Route
                  path="/verification/email/:id*"
                  element={<VerificationEmail />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login-as-user" element={<LoginAsUser />} />
                <Route path="/policies-center" element={<TermsOfUse />} />
              </Routes>
              <ThemedLayout showMainLayout={false} />
            </>
          )}
        </div>
      </SelectedVideoProvider>
    </RefreshToken>
  );
};

export default App;
