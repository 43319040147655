import Form from './Form';
import { PageCard } from 'components/card/PageCard';
import ErrorAlert from 'components/alert/ErrorAlert';
import useCreate from 'hooks/useCreate';
import { Business } from 'apiplatform/interfaces/Business';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import { MutableRefObject, useEffect, useRef } from 'react';
import {
  AuthProviderInterface,
  useAuthContext,
} from 'components/auth/AuthProvider';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

interface CreateProps {
  close?: () => void;
}

const Create = ({ close }: CreateProps) => {
  const auth = useAuthContext();
  const queryClient = useQueryClient();
  const insert = useRef<boolean>(false);

  const { created, loading, error, create } = useCreate<Business>(
    `/businesses`,
    { noInvalidate: true }
  );

  const businessCreated = async (
    createdParam: Business,
    closeParam: () => void,
    authParam: AuthProviderInterface,
    queryClientParam: QueryClient,
    insertParam: MutableRefObject<boolean>
  ) => {
    if (createdParam && !insertParam.current) {
      insertParam.current = true;

      await authParam.refreshToken();
      await queryClientParam.invalidateQueries({
        queryKey: ['businesses'],
      });
      closeParam();
    }
  };

  useEffect(() => {
    businessCreated(created, close, auth, queryClient, insert);
  }, [created, close, auth, queryClient, insert]);

  return (
    <PageCard innerPaddingOff={true}>
      <div className="px-4">
        <Header text="Add Business" />
        {error && <ErrorAlert message={error.message} />}
      </div>

      <Form
        onSubmit={create}
        error={error}
        loading={loading}
        label="Create"
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
      />
    </PageCard>
  );
};

export default Create;
