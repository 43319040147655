import TooltipHorizon from 'components/tooltip';
import { Dispatch, SetStateAction } from 'react';

interface RevisionTemplatesProps {
  setRevision: Dispatch<SetStateAction<string>>;
  setOpenWrapper?: Dispatch<SetStateAction<boolean>>;
  history: string[];
}

const RevisionTemplates = ({
  setRevision,
  setOpenWrapper,
  history,
}: RevisionTemplatesProps) => {
  const templates = [
    'Expand the article to 1000 words',
    'Adopt a more formal tone',
    'Emphasize the human aspect',
  ];

  const selectTemplate = (template: string) => {
    if (setOpenWrapper) {
      setOpenWrapper(false);
      setRevision(template);
    }
  };

  const showTemplates = (title: string, elements: string[]) => {
    return (
      <>
        <h4 className="font-bold text-[#636e91]">{title}</h4>
        {elements.map((template, index) => (
          <div
            className="my-1 cursor-pointer text-sm text-navy-700 hover:font-semibold "
            key={index}
            onClick={() => selectTemplate(template)}
          >
            <TooltipHorizon content="">{template}</TooltipHorizon>
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      {history.length > 0 && (
        <>
          {showTemplates('History:', history)}
          <div className="h-[0.5rem]" />
        </>
      )}
      {showTemplates('Templates:', templates)}
    </div>
  );
};

export default RevisionTemplates;
