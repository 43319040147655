import useCreate from 'hooks/useCreate';
import { useParams } from 'react-router-dom';
import { RestoreDefaultPrompts } from 'apiplatform/interfaces/RestoreDefaultPrompts';
import { Dispatch, SetStateAction, useEffect } from 'react';
import RestoreDialog from './RestoreDialog';

interface RestoreProps {
  close?: () => void;
  setKey: Dispatch<SetStateAction<number>>;
}

const Restore = ({ close, setKey }: RestoreProps) => {
  const { id, ctid } = useParams();

  const {
    create: restore,
    created: restored,
    loading,
  } = useCreate<RestoreDefaultPrompts>(`/businesses/${id}/prompts`);

  useEffect(() => {
    if (restored) {
      close();
      setKey(Date.now());
    }
  }, [restored, close, setKey]);

  return (
    <RestoreDialog
      close={close}
      loading={loading}
      click={() =>
        restore({ contentTheme: `/businesses/content_themes/${ctid}` })
      }
    />
  );
};

export default Restore;
