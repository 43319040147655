import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';

import { FormProps, SubmissionError } from '../../utils/types';
import Submit from '../base/form/Submit';

import Wordpress from 'assets/img/crm/Wordpress.png';
import CenteredButtons from '../base/CenteredButtons';
import { BusinessWordpressConfig } from 'apiplatform/interfaces/BusinessWordpressConfig';
import Button from '../../../components/fields/Button';
import useDelete from 'hooks/useDelete';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<BusinessWordpressConfig>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessWordpressConfig>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  const { del, deleted } = useDelete<BusinessWordpressConfig>();

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof BusinessWordpressConfig]) {
          return;
        }
        setError(errorPath as keyof BusinessWordpressConfig, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<BusinessWordpressConfig> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <>
      <div className="inline-block">
        <img
          src={Wordpress}
          alt="WordPress Logo"
          className="h-[100px] w-[250px] object-contain"
        />
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          register={register}
          name="business"
          placeholder=""
          type="hidden"
          errors={errors}
        />
        <Field
          register={register}
          name="url"
          placeholder="WordPress URL"
          type="text"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="login"
          placeholder="WordPress Login"
          type="text"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="password"
          placeholder="Application Password | Found under Users -> Profiles -> Application Passwords"
          type="password"
          required
          errors={errors}
        />
        <p className="text-sm font-normal capitalize text-navy-700 dark:text-white">
          Additional integrations will be available in future versions.
        </p>
        <CenteredButtons
          extra={`${
            initialValues.login !== undefined
              ? ''
              : 'flex justify-center items-center'
          } `}
        >
          {initialValues.login !== undefined && (
            <Button
              buttonType="button"
              type="blue"
              label="Revoke"
              className="mr-16"
              onClick={() => {
                if (initialValues) {
                  del(initialValues as BusinessWordpressConfig);
                }
              }}
            />
          )}

          <Submit label={label} loading={loading} />
          {footer}
        </CenteredButtons>
      </form>
    </>
  );
};

export default Form;
