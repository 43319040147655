import useUpdate from 'hooks/useUpdate';
import Form from './Form';
import ErrorAlert from 'components/alert/ErrorAlert';
import Button from 'components/fields/Button';
import { BusinessWordpressConfig } from 'apiplatform/interfaces/BusinessWordpressConfig';

interface UpdateProps {
  retrieved: BusinessWordpressConfig | null;
  close: () => void;
}

const Update = ({ retrieved, close }: UpdateProps) => {
  const {
    updated,
    update,
    loading: updateLoading,
    error: updateError,
  } = useUpdate<BusinessWordpressConfig>();

  const item = updated ? updated : retrieved;

  return (
    <>
      {updateError && <ErrorAlert message={updateError.message} />}

      {item && (
        <Form
          onSubmit={(values) => {
            update(item, values);
          }}
          label="Save"
          error={updateError}
          loading={updateLoading}
          initialValues={item}
          footer={
            <Button
              leftMargin="modal"
              type="blue"
              label="Cancel"
              onClick={close}
            />
          }
        />
      )}
    </>
  );
};

export default Update;
