import Header from '../../../../apiplatform/components/base/Header';
import NoAuthCentered from '../../../../layouts/auth/types/NoAuthCentered';
import Card from '../../../../components/card';
import { Link } from 'react-router-dom';

export const TermsOfUse = () => {
  return (
    <NoAuthCentered
      maincard={
        <Card extra="max-w-[1300px] md:max-w-[1300px] md:w-[1300px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <div>
            <Header text="Terms of Use & Privacy Policy">
              <Link
                to="/"
                className="text-sm font-normal text-[#636e91] hover:text-[#636e91] md:text-base"
              >
                Back to Homepage
              </Link>
            </Header>
            <p>August 13th, 2024</p>
            <p className="text-black mt-4 text-sm dark:text-gray-500">
              CustomerGain may collect and store information to provide the
              service’s functionality, improve the service’s quality,
              personalize your experience, track usage of the service,, provide
              customer support, message you, back up our systems and allow for
              disaster recovery, enhance the security of the service, and comply
              with legal obligations.
              <p className="">
                <br />
              </p>
              If you create an account on CustomerGain, we may store your name,
              email address and other information you may provide with your
              account. You can modify the information associated with your
              account in the user profile.
              <p className="">
                <br />
              </p>
              We may store information about your use of the service, such as
              your search activity, the pages you view, the date and time of
              your visit, We also may store information that your computer or
              mobile device provides to us in connection with your use of the
              Service, such as your browser type, type of computer or mobile
              device, browser language, IP address, mobile carrier, phone
              number, unique device identifier, advertising identifier, location
              (including geolocation, beacon based location, and GPS location),
              and requested and referring URLs. You may be able to disallow our
              use of certain location data through your device or browser
              settings, for example by disabling “Location Services” for the
              CustomerGain application in iOS privacy settings.
              <p className="">
                <br />
              </p>
              We, and third parties with whom we partner, may use cookies, web
              beacons, tags, scripts, local shared objects such as HTML5 ,
              advertising identifiers (including mobile identifiers such as
              Apple’s IDFA or Google’s Advertising ID) and similar technology
              (“Cookies”) in connection with your use of the Service, third
              party websites, and mobile applications. Cookies may have unique
              identifiers, and reside, among other places, on your computer or
              mobile device, in emails we send to you, and on our web pages.
              Cookies may transmit information about you and your use of the
              service, such as your browser type, search preferences, IP
              address, and the date and time of your use. Cookies may be
              persistent or stored only during an individual session.
              <p className="">
                <br />
              </p>
              We may rely on third party providers to support or provide some of
              the services that are available through the service. We may also
              rely on third party providers to perform certain services for us
              in connection with your use of the service, such as communications
              and hosting services, network security, technical and customer
              support, tracking and reporting functions, quality assurance
              testing, payment processing, our own marketing of the Service, and
              other functions. We may share information from or about you with
              these third party providers so that they can perform their
              services or complete your requests. These third party providers
              may share information with us that they obtain from or about you
              in connection with providing their services or completing your
              requests. Third party providers may also share this information
              with their subsidiaries, joint ventures, or other companies under
              common control. Some of our web pages utilize framing techniques
              to serve content to you from our third party providers, while
              preserving the look and feel of the Service. In such cases, please
              note that the information you provide is being provided to the
              third party.
              <p className="">
                <br />
              </p>
              We may share information from or about you with our parent
              companies, subsidiaries, joint ventures, or other companies under
              common control, in which case we will require them to honor this
              Privacy Policy. If another company acquires CustomerGain or all or
              substantially all of our assets, that company will possess the
              same information, and will assume the rights and obligations with
              respect to that information as described in this Privacy Policy.
              <p className="">
                <br />
              </p>
              We may investigate and disclose information from or about you if
              we have a good faith belief that such investigation or disclosure
              (a) is reasonably necessary to comply with legal process and law
              enforcement instructions and orders, such as a search warrant,
              subpoena, statute, judicial proceeding, or other legal process
              served on us; (b) is helpful to prevent, investigate, or identify
              possible wrongdoing in connection with the Service; or (c)
              protects our rights, reputation, property, or that of our users,
              affiliates, or the public. If you flag or otherwise complain to
              CustomerGain about content through the Service, we may share the
              substance of your complaint with the contributor of that content
              in order to provide an opportunity for the contributor to respond.
              <p className="">
                <br />
              </p>
              You can close your account at any time, but we may retain
              information about you for the purposes authorized under this
              Privacy Policy unless prohibited by law. For example, we may
              retain information to prevent, investigate, or identify possible
              wrongdoing in connection with the Service or to comply with legal
              obligations.
              <p className="">
                <br />
              </p>
              The Service is intended for general audiences and is not directed
              to children under 13. We do not knowingly collect personal
              information from children under 13. If you become aware that a
              child has provided us with personal information without parental
              consent, please contact us here. If we become aware that a child
              under 13 has provided us with personal information without
              parental consent, we take steps to remove such information and
              terminate the child’s account.
              <p className="">
                <br />
              </p>
              We follow generally accepted industry standards to protect the
              personal information submitted to us, both during transmission and
              once we receive it. However, no method of transmission over the
              Internet or via mobile device, or method of electronic storage, is
              100% secure. Therefore, while we strive to use commercially
              acceptable means to protect your personal information, we cannot
              guarantee its absolute security.
              <p className="">
                <br />
              </p>
              We may revise this Privacy Policy from time to time. The most
              current version of the Privacy Policy will govern our collection,
              use, and disclosure of information about you and will be located
              here.
              <p className="">
                <br />
              </p>
              In effect from 25 May 2018, CustomerGain will Process Personal
              Data in accordance with GDPR (General Data Protection Regulation)
              requirements. https://www.eugdpr.org/
              <p className="">
                <br />
              </p>
              CustomerGain is a “processor” by definition of the GDPR.
              Definition: A processor is a natural or legal person or agency
              that processes data on behalf of a controller. “Processing” is
              defined very broadly in the Directive to include collection, use,
              storage, manipulation, disclosure, disposal, and virtually any
              other action with personal data.
              <p className="">
                <br />
              </p>
              CustomerGain processes data as delegated by the “controller”.
              Definition: A controller is as the natural or legal person or
              public agency that “alone or jointly with others” determines “the
              purposes and means of processing” personal data.
              <p className="">
                <br />
              </p>
              The GDPR defines the data controller as the principal party for
              responsibilities such as collecting consent, managing
              consent-revoking and enabling right to access. A data subject who
              wishes to revoke consent for his or her personal data therefore
              will contact the data controller to initiate the request.
              <p className="">
                <br />
              </p>
              Data Protection Impact Assessment. In effect from 25 May 2018,
              upon Customer’s request, CustomerGain (processor) shall provide
              Customer with reasonable cooperation and assistance needed to
              fulfill Customer’s obligation under the GDPR to carry out a data
              protection impact assessment related to Customer’s use of the
              Services, to the extent Customer does not otherwise have access to
              the relevant information, and to the extent such information is
              available to CustomerGain.
              <p className="">
                <br />
              </p>
              CustomerGain shall return Customer Data to Customer and, to the
              extent allowed by applicable law, delete Customer Data in
              accordance with the procedures and timeframes specified by the
              GDPR.
              <p className="">
                <br />
              </p>
              Notification of Sub-processors and Objection Right for New
              Sub-processors. Customer acknowledges and expressly agrees that
              CustomerGain does engage with Sub-processors and that CustomerGain
              may engage in new Sub-processors at any time. All current
              Sub-processors have expressed their intention to be GDPR compliant
              by May 25th. List of current Sub-processors: SendGrid for Email
              delivery, Twilio for SMS delivery, Amazon AWS for data storage.
              <p className="">
                <br />
              </p>
              CustomerGain maintains security incident management policies and
              procedures and shall notify Customer without undue delay after
              becoming aware of the accidental or unlawful destruction, loss,
              alteration, unauthorized disclosure of, or access to Customer
              Data, including Personal Data, transmitted, stored or otherwise
              Processed by CustomerGain or its Sub-processors of which
              CustomerGain becomes aware (a “Customer Data Incident”).
              <p className="">
                <br />
              </p>
              CustomerGain shall make reasonable endeavors to identify the cause
              of such Customer Data Incident and take those steps as
              CustomerGain deems necessary and reasonable in order to remediate
              the cause of such a Customer Data Incident to the extent the
              remediation is within CustomerGain’s reasonable control. The
              obligations herein shall not apply to incidents that are caused by
              Customer or Customer’s Users.
              <p className="">
                <br />
              </p>
              Information collected by CustomerGain. CustomerGain collects the
              name, email address, mailing address, mobile phone number, and
              credit card information upon signup. CustomerGain uses this
              information for administrative purposes and billing. CustomerGain
              may also use the information to understand and analyze usage and
              preferences in order to improve the product and functionality.
              Data is only used in anonymized or aggregated form.
              <p className="">
                <br />
              </p>
              In compliance with GDPR Article 37 CustomerGain has a designated
              DPO available for inquiries from data subjects on issues relating
              to data protection practices, withdrawal of consent, the right to
              be forgotten, and related rights.
              <p className="">
                <br />
              </p>
              If you have a question about these Terms, or you would like to
              contact us about any of your rights mentioned herein, please
              contact us at cs@customergain.com
            </p>
          </div>
        </Card>
      }
    ></NoAuthCentered>
  );
};
