import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResource, SubmissionError } from 'apiplatform/utils/types';
import axios from 'axios';
import { buildQueryKeys, throwSubmissionError } from 'hooks';
import { useState } from 'react';

interface IMediaOptions {
  noInvalidate?: boolean;
}

interface IAppendElement {
  name: string;
  blobValue: Blob | string;
  filename?: string;
}

const useMedia = <IResource extends ApiResource>(
  iri: string,
  options?: IMediaOptions
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate,
    error,
    data: created,
  } = useMutation<IResource, SubmissionError, IAppendElement[]>({
    mutationFn: async (resources) => {
      setLoading(() => true);
      try {
        const formData = new FormData();
        resources.forEach((resource) => {
          if (resource.blobValue instanceof Blob) {
            formData.append(
              resource.name,
              resource.blobValue,
              resource.filename
            );
          } else {
            formData.append(resource.name, resource.blobValue);
          }
        });

        const response = await axios.post(
          process.env.REACT_APP_API_URL + iri,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            timeout: 300 * 1000,
          }
        );

        return response.data;
      } catch (e) {
        if (e instanceof Error) {
          throwSubmissionError(e);
        }
        throw e;
      }
    },
    onSuccess: () => {
      if (!(options?.noInvalidate === true)) {
        queryClient.invalidateQueries({
          queryKey: [buildQueryKeys(iri)[0]],
        });
      }
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const create = (resource: IAppendElement[]) => {
    mutate(resource);
  };

  return { loading, create, error, created };
};

export default useMedia;
