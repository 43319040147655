import useUpdate from 'hooks/useUpdate';
import Form from './Form';
import { BusinessPrompt } from 'apiplatform/interfaces/BusinessPrompt';
import { ReactElement } from 'react';

interface UpdateProps {
  retrieved: BusinessPrompt;
  footer?: ReactElement;
}

const Update = ({ retrieved, footer }: UpdateProps) => {
  const { update, loading, error } = useUpdate<BusinessPrompt>();

  return (
    <Form
      onSubmit={(values) => {
        update(retrieved, values);
      }}
      error={error}
      loading={loading}
      initialValues={retrieved}
      label="Save"
      footer={footer}
    />
  );
};

export default Update;
