import EmptyModalBox from '../../apiplatform/components/base/modal/EmptyModalBox';
import ShowTutorialsMenu from 'apiplatform/components/tutorials/ShowTutorialsMenu';

const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-[12px] pb-8 pt-3 xl:flex-row">
      <div>
        <p className="mb-4 text-center text-sm font-medium text-[#636e91] sm:!mb-0 md:text-lg">
          <span className="mb-4 text-center text-sm text-[#636e91] sm:!mb-0 md:text-base">
            ©{new Date().getFullYear()} {process.env.REACT_APP_BRAND_NAME}. All
            Rights Reserved.
          </span>
        </p>
      </div>
      <div>
        <EmptyModalBox
          width={1000}
          item={
            <p className="mb-4 text-center text-sm font-medium text-[#636e91] sm:!mb-0 md:text-lg">
              <span className="mb-4 cursor-pointer text-center text-sm text-[#636e91] sm:!mb-0 md:text-base">
                Tutorials &amp; How-to Videos
              </span>
            </p>
          }
        >
          <ShowTutorialsMenu />
        </EmptyModalBox>
      </div>
    </div>
  );
};

export default Footer;
