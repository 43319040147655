export interface TagData {
    tag: string;
    entityField: string;
    input: string;
    comment: string;
  }
  
  export const tagData: TagData[] = [
    { tag: '[transcript]', entityField: 'BusinessTranscript::content', input: 'Update Transcript → Transcript', comment: '' },
    { tag: '[timestamps]', entityField: 'BusinessTranscript::timestamps', input: 'Update Transcript → Timestamps', comment: 'This is the same field as the content but includes timestamps before each sentence.' },
    { tag: '[title]', entityField: 'Business::title', input: 'Update Business → Business Name', comment: '' },
    { tag: '[author]', entityField: 'Business::author', input: 'Update Business → Your Name and Role', comment: '' },
    { tag: '[keywords]', entityField: 'Business::keywords', input: 'Update Business → What "keyword phrases" would someone…', comment: '' },
    { tag: '[description]', entityField: 'Business::description', input: 'Update Business → What does your company do?', comment: '' },
    { tag: '[reason]', entityField: 'Business::reason', input: 'Update Business → Why did you start your company?', comment: '' },
    { tag: '[differentiator]', entityField: 'Business::differentiator', input: 'Update Business → What makes your company different?', comment: '' },
    { tag: '[interaction]', entityField: 'Business::interaction', input: 'Update Business → Who cares about that a lot?', comment: '' },
    { tag: '[theme_keywords]', entityField: 'ContentTheme::keywords', input: 'Update Content Theme → Keywords', comment: '' },
    { tag: '[theme_description]', entityField: 'ContentTheme::description', input: 'Update Content Theme → Description', comment: '' },
    { tag: '[url]', entityField: 'Business::url', input: 'Update Business → What is your Website?', comment: '' },
    { tag: '[content]', entityField: 'ArticleRevisionResource::content', input: 'Queue → Content', comment: 'The content of the article' },
    { tag: '[prompt]', entityField: 'ArticleRevisionResource::prompt', input: 'Queue → Revision Request', comment: 'The prompt which is completed by user in the textarea or selected from the list' },
  ];
  