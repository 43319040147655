import React from 'react';
import { useState } from 'react';
import { PropsWithChildren } from 'react';

interface SocialAppTemplateProps extends PropsWithChildren {
  extra: string;
  message: string;
  link?: string;
  lastChild?: boolean;
  copyText?: boolean;
  text?: string;
  url?: boolean | string;
}

export const SocialAppTemplate: React.FC<SocialAppTemplateProps> = ({
  children,
  message,
  extra,
  link,
  lastChild,
  copyText,
  text,
  url,
}) => {
  const [textToCopy] = useState<string>(text || '');
  const [showBanner, setShowBanner] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowBanner(true);
      setTimeout(() => setShowBanner(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };
  const handleCopy = () => {
    copyToClipboard(textToCopy);
  };

  return (
    <div className={` ${lastChild ? '' : 'mr-6'}  `}>
      {copyText ? (
        <>
          <button
            onClick={handleCopy}
            className={`linear block rounded-xl px-7 py-3  text-base  font-medium
              text-white transition duration-200 ${extra}`}
          >
            {children}
          </button>
          <p className=" pl-2 text-center text-sm text-navy-700">{message}</p>

          {showBanner && (
            <div className="fixed left-1/2 top-0 z-50 -translate-x-1/2 transform border-b-2 border-gray-400 bg-gray-200 px-4 py-2 shadow-lg">
              Text copied to clipboard!
            </div>
          )}
        </>
      ) : (
        <a
          className={`btn block ${
            url == null ? 'pointer-events-none opacity-30 ' : ''
          }`}
          href={link}
          data-posttype="text"
          target="_blank"
          rel="noreferrer"
          data-size="large"
          // onClick={(e) => {
          //   if (!url) e.preventDefault();
          // }}
        >
          <div
            className={`linear block rounded-xl px-7 py-3  text-base  font-medium
            text-white transition duration-200 ${extra}`}
          >
            {children}
          </div>
          <p className=" text-center text-sm text-navy-700">{message}</p>
        </a>
      )}
    </div>
  );
};
