import { DragEvent } from 'react';
import { useSelectedVideo } from 'views/main/dashboards/default/components/Video/SelectedVideoContext';
import { Subpage } from './List';
import VideoUploadButton from 'views/main/dashboards/default/components/VideoUploadButton';
import { MdFileUpload } from 'react-icons/md';

interface SuggestionsProps {
  subpage: Subpage;
}

const MediaSelector = ({ subpage }: SuggestionsProps) => {
  const [, setSelectedVideo] = useSelectedVideo();

  const stopPropagation = (evt: DragEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const dropHandler = async (evt: DragEvent<HTMLDivElement>) => {
    const file = evt.dataTransfer.files[0];
    stopPropagation(evt);
    handleFile(file);
  };

  const selectFile = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = (evt) => {
      if (evt.target instanceof HTMLInputElement) {
        handleFile(evt.target.files[0]);
      }
    };
  };

  const handleFile = (file: File) => {
    setSelectedVideo({
      transcriptId: subpage.transcriptId,
      file: file,
    });
  };

  return (
    <div>
      <div
        onDrop={dropHandler}
        onDragEnter={stopPropagation}
        onDragOver={stopPropagation}
        onDragLeave={stopPropagation}
        onClick={selectFile}
      >
        <VideoUploadButton
          title="Select File"
          icon={
            <MdFileUpload className="text-[80px] text-blue-900 dark:text-white" />
          }
          description={
            <>
              supported file types: <b>mp4 mkv avi m4a mp3</b>
              <br />
              max file size: <b>2GB</b>
              <br />
              drop your file here or click to select a file
            </>
          }
        />
      </div>
    </div>
  );
};

export default MediaSelector;
