// StepBar.tsx
import React from 'react';

interface StepBarProps {
  steps: string[];
  currentStep: number;
}

const StepBar: React.FC<StepBarProps> = ({ steps, currentStep }) => {
  const circleSize = 6;
  const lineWidth = 1;

  const renderConnectingLines = () => {
    const lines = [];
    const lineWidths = [246, 270]; // Customize the widths as needed

    for (let i = 1; i < currentStep; i++) {
      lines.push(
        <div
          key={i}
          className={`mb-8 ml-1 mr-4 flex pt-1 h-${lineWidth} w-${
            lineWidths[i - 1]
          } ${
            i < currentStep - 1
              ? 'bg-gray-500'
              : 'bg-gradient-to-r from-gray-500 via-gray-100 to-white'
          }`}
        />
      );
    }
    return lines;
  };

  return (
    <div className="flex items-center py-4 ">
      <div className="flex md:space-x-28  lg:space-x-44">
        {steps.map((step, index) => (
          <div key={index} className="relative  flex flex-col items-center">
            <div
              className={`w-${circleSize} h-${circleSize} relative overflow-hidden rounded-full bg-white`}
            >
              <div
                className={`h-full w-full rounded-full ${
                  index < currentStep - 1 ? 'bg-gray-500' : 'bg-white'
                }`}
              />
              <div
                className={`w-${circleSize - 2} h-${
                  circleSize - 2
                } absolute left-1 top-1 rounded-full bg-gradient-to-r from-blue-700 to-blue-800`}
              />
            </div>
            <span
              className={`mb-1 pt-8 font-medium text-sm lg:text-base ${
                index < currentStep - 1 ? 'text-gray-500' : 'text-white'
              }`}
            >
              {step}
            </span>
          </div>
        ))}
      </div>
      <div className="absolute mb-5 ml-14 items-center">
        {currentStep > 1 && <div className="">{renderConnectingLines()}</div>}
      </div>
    </div>
  );
};

export default StepBar;
