import axios from 'axios';
import useLocalStorage from './useLocalStorage';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const signInUrl = process.env.REACT_APP_API_URL + '/login_check';

export interface UserData {
  username: string;
  password: string;
}

export interface User {
  id: number;
  token: string;
  refresh_token: string;
  isFirstLogin: boolean;
}

export interface jwtToken {
  iat: number;
  exp: number;
  id: number;
  roles: string[];
  username: string;
  permissions: BusinessPermission[];
}

export interface BusinessPermission {
  id: number;
  roles: string[];
}

const useAuth = () => {
  const [user, setUser] = useLocalStorage({ key: 'user', defaultValue: null });
  const navigate = useNavigate();

  const signIn = async (data: UserData) => {
    try {
      let authresult = await axios.post(signInUrl, data);
      const user: User = authresult.data;
      setUser(user);

      if (user.isFirstLogin) {
        navigate('/businesses/manage');
      }
    } catch (err) {
      throw err;
    }
  };

  const signOut = () => {
    setUser(null);
  };

  const decodeToken = (): jwtToken | null => {
    try {
      return jwt_decode(user.token);
    } catch (e) {
      return null;
    }
  };

  const getEmail = (): string => {
    try {
      const object: jwtToken = jwt_decode(user.token);

      return object.username;
    } catch (e) {
      return '';
    }
  };

  const isAuthorized = (): boolean => {
    return !!user;
  };

  const refreshToken = async () => {
    try {
      const newUser: User = (
        await axios.post(process.env.REACT_APP_API_URL + '/token/refresh', {
          refresh_token: user.refresh_token,
        })
      ).data;
      setUser(newUser);
      return true;
    } catch (e) {
      signOut();
      return false;
    }
  };

  const setToken = (token: string, refreshToken: string | null = null) => {
    setUser({ token: token, refresh_token: refreshToken });

    navigate('/businesses/manage');
  };

  const getPermissions = (): BusinessPermission[] => {
    try {
      const object: jwtToken = jwt_decode(user.token);

      return object.permissions;
    } catch (e) {
      return [];
    }
  };

  const getPermision = (id: number): BusinessPermission | null => {
    const permissions = getPermissions();

    return permissions.find((permission) => permission.id === id) || null;
  };

  const isOwner = (): boolean => {
    if (getPermissions().length === 0) return true;

    return getPermissions()
      .map((p: BusinessPermission) => p.roles)
      .flat()
      .includes('ROLE_OWNER');
  };

  const isBusinessOwner = (businessId: number): boolean => {
    const permission = getPermision(businessId);

    return permission ? permission.roles.includes('ROLE_OWNER') : false;
  };

  const isSingleNotOwnerBusiness = () => {
    const permissions = getPermissions();

    if (permissions.length === 1) {
      return permissions[0].roles.includes('ROLE_OWNER') === false;
    }

    return false;
  };

  const getId = (): number => {
    try {
      const object: jwtToken = jwt_decode(user.token);

      return object.id;
    } catch (e) {
      return 0;
    }
  };

  return {
    user,
    signIn,
    signOut,
    getEmail,
    isAuthorized,
    decodeToken,
    refreshToken,
    setToken,
    getPermissions,
    getPermision,
    isOwner,
    isBusinessOwner,
    isSingleNotOwnerBusiness,
    getId,
  };
};

export default useAuth;
