import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import CenteredButtons from '../../apiplatform/components/base/CenteredButtons';
import Button from '../../components/fields/Button';
import { PageCard } from '../../components/card/PageCard';

interface EditTweetProps {
  close?: () => void;
  onSave?: (newContent: string) => void;
  tweet?: string;

  tweetLoading?: boolean;
}
export const EditTweet = ({
  close,
  tweetLoading,
  tweet,
  onSave,
}: EditTweetProps) => {
  const [content, setContent] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);
  const saveChanges = () => {
    const cleanedContent = content.replace(/<p>/g, '').replace(/<\/p>/g, '');

    onSave && onSave(cleanedContent);
    close();
    closeEditor();
  };
  const closeEditor = () => {
    setEditing(false);
    close();
  };

  useEffect(() => {
    const plainTextContent = React.isValidElement(tweet)
      ? ReactDOMServer.renderToStaticMarkup(tweet)
      : String(tweet);

    // Convert HTML entities to characters
    const decodedContent =
      new DOMParser().parseFromString(plainTextContent, 'text/html').body
        .textContent || '';

    // Remove unwanted HTML tags
    const cleanedContent = decodedContent.replace(/<\/?[^>]+(>|$)/g, '');

    // Exclude double quotes at the start and end of the tweet
    const trimmedContent = cleanedContent.trim();
    const isWrappedInQuotes =
      trimmedContent.startsWith('"') && trimmedContent.endsWith('"');
    const finalContent = isWrappedInQuotes
      ? trimmedContent.slice(1, -1)
      : trimmedContent;

    setContent(finalContent);
  }, [tweet]);
  const handleEditorChange = (newContent: string) => {
    setContent(newContent);
  };

  return (
    <PageCard>
      <div className="flex pt-6">
        <Editor
          value={content}
          onEditorChange={handleEditorChange}
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          init={{
            branding: false,
            menubar: false,
            height: 'auto',
            width: '600px',
            autoresize_bottom_margin: 0,
            plugins: ['autoresize'],
            toolbar: 'undo redo',
            content_style: 'body { font-size: 14px }',
            skin: 'oxide',
          }}
        />
      </div>
      <CenteredButtons modal >
        <Button type="blue" label="Save" onClick={saveChanges} />
        <Button
          type="red"
          label="Cancel"
          leftMargin="modal"
          onClick={closeEditor}
        />
      </CenteredButtons>
    </PageCard>
  );
};
