import { SubmissionError, SubmissionErrors } from 'apiplatform/utils/types';
import { AxiosError } from 'axios';

const buildQueryKeys = (iri: string) => {
  let keys = new Array<string>();

  const url = iri.split('?');
  url[0].split('/').forEach((token) => {
    const trimmedToken = token.trim();
    if (trimmedToken !== '') {
      keys.push(trimmedToken);
    }
  });
  if (url.length > 1) {
    keys.push(url[1]);
  }

  return keys;
};

const throwSubmissionError = (createError: Error) => {
  if (createError instanceof AxiosError) {
    const data = createError.response?.data;
    if (data.violations) {
      const violations: { propertyPath: string; message: string }[] =
        data.violations;

      if (violations instanceof Array) {
        const errors = violations.reduce((errors, violation) => {
          if (errors[violation.propertyPath]) {
            errors[violation.propertyPath] += '\n' + violation.message;
          } else {
            errors[violation.propertyPath] = violation.message;
          }
          return errors;
        }, {} as SubmissionErrors);

        const error =
          data['hydra:description'] ||
          data['hydra:title'] ||
          data['message'] ||
          'An error occurred.';

        throw new SubmissionError(error, errors);
      }
    }
  }
  throw createError;
};

export { buildQueryKeys, throwSubmissionError };
