import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import TResource from '../apiplatform/components/businessarticle/type';

interface SaveData {
  content: string;
  title: string;
}

interface UseAutosaveProps {
  article: TResource;
  setUpdate: (article: TResource, data: SaveData) => void;
  content: string;
  title: string;
}

const useAutosave = ({ article, setUpdate, content, title }: UseAutosaveProps) => {
  const [autosaveStatus, setAutosaveStatus] = useState<'idle' | 'saving' | 'saved'>('idle');

  const debouncedSave = useRef(
    debounce((newContent: string, newTitle: string) => {
      setAutosaveStatus('saving');
      setUpdate(article, { content: newContent, title: newTitle });

      setTimeout(() => {
        setAutosaveStatus('saved');
        setTimeout(() => setAutosaveStatus('idle'), 2000);
      }, 1000);
    }, 2000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return {
    debouncedSave,
    autosaveStatus,
  };
};

export default useAutosave;
