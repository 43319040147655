import { Editor } from '@tinymce/tinymce-react';

const EmptyEditorPreloader = () => {
  return (
    <div className="hidden">
      <Editor
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
        init={{
          license_key: 'gpl',
        }}
      />
    </div>
  );
};

export default EmptyEditorPreloader;
