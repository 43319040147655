import { matchPath, useLocation } from 'react-router-dom';
import routes from 'routes';
import BreadcrumbSeparator from './BreadcrumbSeparator';

const CurrentPageBreadcrumb = () => {
  const path = useLocation().pathname;
  let currentPage = '';
  routes.forEach((route) => {
    route.items?.forEach((subitem) => {
      if (matchPath(subitem.path, path)) {
        currentPage = subitem.name;
      }
    });
  });

  return currentPage !== '' ? (
    <>
      <BreadcrumbSeparator />
      {currentPage}
    </>
  ) : null;
};

export default CurrentPageBreadcrumb;
