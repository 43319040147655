import { MdLocationPin } from 'react-icons/md';
import Icon from '@mdi/react';
import { mdiMusic, mdiTools } from '@mdi/js';

import BusinessesList from 'apiplatform/components/business/List';
import PromptSettings from 'apiplatform/components/businessprompt/List';
import ListTranscripts from 'apiplatform/components/businesstranscript/List';
import ListArticle from 'apiplatform/components/businessarticle/List';
import ListThemes from 'apiplatform/components/contenttheme/List';
import ListManagers from 'apiplatform/components/manager/List';

import { Navigate } from 'react-router-dom';
import LoginAsUser from 'views/auth/loginAsUser/LoginAsUser';

const routes: RoutesType[] = [
  {
    menuItem: false,
    name: 'Home',
    path: '/',
    layout: '',
    component: <Navigate to="/businesses/manage" />,
  },
  {
    menuItem: false,
    name: '',
    path: '/login-as-user',
    layout: '',
    icon: <MdLocationPin className="text-inherit h-5 w-5" />,
    match: `/*`,
    component: <LoginAsUser />,
  },
  {
    menuItem: false,
    name: 'User Manager',
    path: '/managers',
    layout: '',
    component: <ListManagers />,
  },
  {
    menuItem: true,
    name: 'Businesses',
    path: '/businesses/manage',
    layout: '',
    icon: <MdLocationPin className="text-inherit h-5 w-5" />,
    match: `/*`,
    component: <BusinessesList />,
    isSingleNotOwnerBusiness: true,
  },
  {
    menuItem: true,
    name: 'Media',
    layout: 'business',
    path: `/business/`,
    icon: (
      <Icon
        path={mdiMusic}
        size={0.7}
        className="text-inherit ml-[3px] h-5 w-5"
      />
    ),
    secondary: true,
    match: `/business/:id/theme/:ctid/*`,
    collapse: true,
    items: [
      {
        menuItem: true,
        name: 'Queue',
        layout: '',
        path: '/business/:id/theme/:ctid/queue',
        // query: '?allThemes=1',
        match: `/business/:id/theme/:ctid/*`,
        component: <ListArticle />,
        secondary: true,
        permission: ['ROLE_QUEUE', 'ROLE_OWNER'],
      },
      {
        menuItem: true,
        layout: '',
        name: 'Generator',
        path: `/business/:id/theme/:ctid/transcripts`,
        match: `/business/:id/theme/:ctid/*`,
        component: <ListTranscripts />,
        secondary: true,
        permission: ['ROLE_OWNER'],
      },
      {
        menuItem: true,
        name: 'Themes (Advanced)',
        layout: '',
        path: '/business/:id/theme/:ctid/themes',
        match: `/business/:id/theme/:ctid/*`,
        component: <ListThemes />,
        secondary: true,
        permission: ['ROLE_OWNER'],
      },
    ],
  },
  {
    menuItem: true,
    name: 'Dev Menu',
    layout: 'business',
    path: `/business/`,
    icon: (
      <Icon
        path={mdiTools}
        size={0.7}
        className="text-inherit ml-[3px] h-5 w-5"
      />
    ),
    secondary: true,
    match: `/business/:id/*`,
    collapse: true,
    permission: ['ROLE_OWNER'],
    items: [
      {
        menuItem: true,
        layout: '',
        name: 'Prompt Settings (Advanced)',
        path: `/business/:id/theme/:ctid/prompts`,
        match: `/business/:id/theme/:ctid/*`,
        component: <PromptSettings />,
        secondary: true,
      },
    ],
  },
];

export default routes;
