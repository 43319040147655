import useAuth from 'components/auth/useAuth';
import { useEffect, useState } from 'react';

const RefreshToken = ({ children }: React.PropsWithChildren) => {
  const { decodeToken, refreshToken, signOut } = useAuth();
  const [refreshIn, setRefreshIn] = useState<boolean>(false);
  const token = decodeToken();

  useEffect(() => {
    if (token !== null && refreshIn !== true) {
      const refreshIn = Math.round(token.exp - Date.now() / 1000) - 5 * 60;
      if (refreshIn > 0) {
        setRefreshIn(true);
        setTimeout(async () => {
          await refreshToken();
          setRefreshIn(false);
        }, refreshIn * 1000);
      } else {
        signOut();
      }
    }
  }, [token, refreshIn, refreshToken, signOut]);

  return <>{children}</>;
};

export default RefreshToken;
