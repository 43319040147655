import Loader from '../base/Loader';
import Create from './Create';
import Update from './Update';
import { PageCard } from '../../../components/card/PageCard';
import { BusinessWordpressConfig } from 'apiplatform/interfaces/BusinessWordpressConfig';
import { Business } from 'apiplatform/interfaces/Business';
import useRetrieve from 'hooks/useRetrieve';

interface ShowProps {
  close?: () => void;
  business: Business;
}

const Show = ({ business, close }: ShowProps) => {
  const { retrieved, loading, error } = useRetrieve<BusinessWordpressConfig>(
    `/businesses/${business.id}/wordpress-configs`,
    undefined,
    {
      retry: 0,
    }
  );

  if (retrieved) {
    retrieved['@id'] = `/businesses/business_wordpress_configs/${retrieved.id}`;
  }

  return (
    <PageCard>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error ? (
            <Create close={close} business={business} />
          ) : (
            <Update close={close} retrieved={retrieved} />
          )}
        </>
      )}
    </PageCard>
  );
};

export default Show;
