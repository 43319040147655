import Dropdown from 'components/dropdown';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import { Editor as TinyMCEEditor } from 'tinymce';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { GrList, GrRevert } from 'react-icons/gr';
import { MdSend } from 'react-icons/md';
import RevisionTemplates from './RevisionTemplates';
import { ArticleRevisionRequest } from 'apiplatform/interfaces/ArticleRevisionRequest';
import { useParams } from 'react-router-dom';
import Loader from '../base/Loader';
import useStream from 'hooks/useStream';

interface RevisionProps {
  content: string;
  editorRef: MutableRefObject<TinyMCEEditor | null>;
}

const Revision = ({ content, editorRef }: RevisionProps) => {
  const [revision, setRevision] = useState<string>('');
  const [isModified, setIsModified] = useState<boolean>(false); // New state for tracking modifications
  const [history, setHistory] = useState<string[]>([]);
  const [revisionTemplatesHistory, setRevisionTemplatesHistory] = useState<
    string[]
  >(JSON.parse(localStorage.getItem('revisionTemplatesHistory') || '[]'));

  const { id } = useParams();

  const {
    stream,
    streaming,
    content: streamContent,
    abort,
    completed,
  } = useStream<ArticleRevisionRequest>(`/businesses/${id}/article_revision`);

  const responseProcessed = useRef<boolean>(false);

  useEffect(() => {
    if (completed && responseProcessed.current === false) {
      responseProcessed.current = true;
      editorRef.current.setContent(streamContent);
    }
  }, [completed, responseProcessed, editorRef, streamContent]);

  useEffect(() => {
    if (streaming) {
      editorRef.current.setContent(streamContent);
    }
  }, [streaming, streamContent, editorRef]);

  const saveHistory = (history: string[]) => {
    localStorage.setItem('revisionTemplatesHistory', JSON.stringify(history));
  };

  const revertClick = () => {
    if (history.length < 1) {
      return;
    }

    editorRef.current.setContent(history.pop() as string);
    setHistory([...history]);
  };

  return (
    <>
      <div className="px-4 py-6 text-[#636e91]">
        To re-author or revise the article, just enter your desired changes
        below. For example you might request: "Expand the article to 1000
        words", "Adopt a more formal tone" or "Emphasize the human aspect".
      </div>

      {streaming && (
        <div className="pl-4">
          <Loader
            title={
              <>
                Revision is being generated,{' '}
                <span
                  className="cursor-pointer text-brand-500"
                  onClick={() => {
                    abort();
                    revertClick();
                  }}
                >
                  click here
                </span>{' '}
                to stop and revert...
              </>
            }
          />
        </div>
      )}

      {!streaming && (
        <div className="relative pr-[40px] ">
          <TooltipHorizon
            block
            content={
              history.length > 0
                ? 'You have unsaved changes. Save them to keep your updates.'
                : ''
            }
          >
            <textarea
              value={revision}
              onChange={(e) => {
                const newValue = e.target.value;
                setRevision(newValue);
                setIsModified(newValue !== '');
              }}
              placeholder="Revision Request..."
              className={`!inline-block flex min-h-[105px] w-full items-center justify-center rounded-xl border p-3 text-sm outline-none 
              ${isModified ? 'border-2 border-brand-800' : 'border-gray-200'} 
              dark:!border-white/10 dark:text-white`}
            />
          </TooltipHorizon>
          <span className="absolute right-0 top-0">
            <Dropdown
              button={
                <TooltipHorizon content="">
                  <IconButton>
                    <GrList />
                  </IconButton>
                </TooltipHorizon>
              }
              children={
                <RevisionTemplates
                  setRevision={setRevision}
                  history={revisionTemplatesHistory}
                />
              }
              classNames="w-[500px] rounded-xl bg-white bottom-0 right-[40px] px-4 py-2 border border-gray-100"
            />
          </span>
          <span
            className="absolute right-0 top-[50px] mt-[-14.5px]"
            onClick={revertClick}
          >
            <TooltipHorizon content="Revert">
              <IconButton disabled={history.length < 1}>
                <GrRevert />
              </IconButton>
            </TooltipHorizon>
          </span>
          <span
            className="absolute bottom-0 right-0"
            onClick={() => {
              if (revision === '') {
                return;
              }

              if (revisionTemplatesHistory.length > 9) {
                revisionTemplatesHistory.pop();
              }
              revisionTemplatesHistory.unshift(revision);
              setRevisionTemplatesHistory(revisionTemplatesHistory);
              saveHistory(revisionTemplatesHistory);

              setHistory([...history, content]);
              stream({ content: content, prompt: revision });
              responseProcessed.current = false;
            }}
          >
            <TooltipHorizon content="Submit">
              <IconButton disabled={revision === ''}>
                <MdSend />
              </IconButton>
            </TooltipHorizon>
          </span>
        </div>
      )}
    </>
  );
};

export default Revision;
