import { useQueryClient } from '@tanstack/react-query';
import { ApiResource } from 'apiplatform/utils/types';
import { buildQueryKeys } from 'hooks';
import useRetrieve from 'hooks/useRetrieve';
import { useState } from 'react';

interface ReloadOnCompleteProps<Type extends ApiResource> {
  record: Type;
}

const ReloadOnComplete = <Type extends ApiResource>({
  record,
}: ReloadOnCompleteProps<Type>) => {
  const [completed, setCompleted] = useState(false);

  const { retrieved } = useRetrieve<Type>(record['@id'], undefined, {
    refetchInterval: 10000,
  });
  const queryClient = useQueryClient();
  if (
    !completed &&
    (('status' in retrieved && retrieved.status === 'complete') ||
      ('businessTranscriptStatus' in retrieved &&
        retrieved.businessTranscriptStatus === 'ready'))
  ) {
    setCompleted(true);
    queryClient.invalidateQueries({
      queryKey: buildQueryKeys(`/businesses`),
    });
  }

  return <></>;
};

export default ReloadOnComplete;
