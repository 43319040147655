import { Tooltip } from '@chakra-ui/tooltip';
import { PropsWithChildren, ReactNode } from 'react';

interface TooltipHorizonProps extends PropsWithChildren {
  extra?: string;
  content: ReactNode;
  block?: boolean;
  placement?: 'left' | 'right' | 'top' | 'bottom';
}

const TooltipHorizon = ({
  extra,
  content,
  placement,
  children,
  block,
}: TooltipHorizonProps) => {
  return content ? (
    <Tooltip
      placement={placement ? placement : 'bottom'}
      label={
        <div className="rounded-lg bg-white pb-1 pl-4 pr-4 pt-1">{content}</div>
      }
      className={`w-max rounded-xl border border-gray-100 bg-white px-2 py-1 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none ${
        extra ? extra : ''
      }`}
    >
      <span className={`${block ? 'block' : 'inline-block'} leading-[0px]`}>
        {children}
      </span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default TooltipHorizon;
