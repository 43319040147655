import { IoMdTime } from 'react-icons/io';

function TimelineItem(props: {
  current?: boolean | string;
  day: string;
  weekday: string;
  hours: string;
  title: string;
  mb?: string;
  extra?: string;
}) {
  const { current, day, weekday, hours, title, mb, extra } = props;

  return (
    <div
      className={`flex w-full items-end justify-between gap-4 ${mb} rounded-xl p-1.5 ${
        current
          ? 'bg-brand-900 dark:!bg-brand-400 '
          : 'bg-white dark:!bg-navy-700'
      } `}
    >
      {/* left side */}
      <div className="flex items-center gap-3">
        <div
          className={`flex h-20 w-20 flex-col items-center justify-center ${
            extra ? extra : ''
          } rounded-xl ${
            current ? 'bg-brand-900' : 'bg-lightPrimary dark:!bg-navy-900'
          } `}
        >
          <p
            className={`text-sm font-bold ${
              current ? 'text-white' : 'text-[#636e91] dark:text-white'
            } `}
          >
            {weekday}
          </p>
          <h5
            className={`text-[28px] font-bold ${
              current ? 'text-white' : 'text-navy-700 dark:text-white'
            } `}
          >
            {day}
          </h5>
        </div>
        <div className="flex max-w-[82%] flex-col">
          <h5
            className={`text-base font-bold leading-6 ${
              current ? 'text-white' : 'text-navy-700 dark:text-white'
            } `}
          >
            {title}
          </h5>
          <div
            className={`mt-1 flex items-center gap-2 ${
              current ? 'text-white' : 'text-[#636e91]'
            } `}
          >
            <p className="">
              <IoMdTime />
            </p>
            <p className="ml-[-5px] text-sm font-bold"> {hours} </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineItem;
