import { AiFillExclamationCircle } from "react-icons/ai";
import SolidSubtleAlert from "views/admin/main/others/notifications/components/SolidSubtleAlert";

interface ErrorAlertProps {
  message: string
}

const ErrorAlert = ({ message }: ErrorAlertProps) => {
  return (
    <SolidSubtleAlert
      title="Error"
      description={ message }
      icon={<AiFillExclamationCircle />}
      iconColor="text-white"
      closeBg="hover:bg-white/20 text-white"
      bg="bg-red-500 dark:!bg-red-400"
      solid="solid"
    />
  );
};

export default ErrorAlert;
