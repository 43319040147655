import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';
import { SubmissionError, FormProps } from '../../utils/types';
import Submit from '../base/form/Submit';
import { Business } from 'apiplatform/interfaces/Business';
import CenteredButtons from '../base/CenteredButtons';
import { ExplainerText } from '../base/ExplainerText';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<Business>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<Business>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof Business]) {
          return;
        }
        setError(errorPath as keyof Business, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<Business> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="max-h-[500px] overflow-y-scroll px-4">
        <ExplainerText
          leftPadding={false}
          text="In order to produce the best content for you, we need to understand a few things about your business. "
        />
        <ExplainerText
          leftPadding={false}
          text="The questions below allow us to understand the positioning for your business. Your positioning needs to communicate: Who is this for? Why is it different? Why is it better? Why pick us over the other guys? We will make sure that your content is guided by this framework so it reinforces your message across all the channels where you publish content."
        />
        <ExplainerText
          leftPadding={false}
          text={
            <>
              For more on positioning,{' '}
              <a
                href="https://www.youtube.com/watch?v=vM_1G1LCotU"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className=" inline-block  font-bold">
                  {' '}
                  watch this interview
                </p>
              </a>{' '}
              with positioning expert April Dunford by Shane Parrish. It’s a
              fantastic interview that dives into this topic.
            </>
          }
        />
        <Field
          register={register}
          name="name"
          placeholder="Business Name"
          type="text"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="author"
          placeholder="Your Name and Role"
          type="text"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="url"
          placeholder="What is your Website?"
          type="text"
          errors={errors}
        />
        <Field
          register={register}
          name="description"
          placeholder="What does your company do?"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="reason"
          placeholder="Why did you start your company?"
          exampleText="How do you look at your market differently than your competitors? What is your unique view?"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="reason"
          placeholder="What are the alternatives to your product or solution?"
          exampleText="Competitors or different approaches"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="differentiator"
          placeholder="What makes your company different?"
          exampleText="What capabilities do you have that the alternatives don’t have?"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="reason"
          placeholder="Why do those features matter?"
          exampleText="What value can those features deliver for a business that no one else can?"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="interaction"
          placeholder="Who cares about that a lot?"
          exampleText="Who is your customer?"
          type="textarea"
          required
          errors={errors}
        />
        <Field
          register={register}
          name="keywords"
          placeholder='What "keyword phrases" would someone type into Google to find your business?'
          type="textarea"
          required
          errors={errors}
        />
      </div>

      <CenteredButtons modal>
        <Submit label={label} loading={loading} />
        {footer}
      </CenteredButtons>
    </form>
  );
};

export default Form;
