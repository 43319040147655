import { PageCard } from 'components/card/PageCard';
import { Business } from 'apiplatform/interfaces/Business';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import useDelete from 'hooks/useDelete';
import CenteredButtons from '../base/CenteredButtons';
import { useEffect } from 'react';

interface DeleteProps {
  close?: () => void;
  item: Business;
}

const Delete = ({ close, item }: DeleteProps) => {
  const { del, deleted, loading } = useDelete<Business>();

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted, close]);

  return (
    <PageCard>
      <Header text="Delete Business" />

      <p>
        Are you sure you want to delete <b>{item.name}</b>?
      </p>

      <CenteredButtons modal>
        <Button
          type="red"
          label="Delete"
          loading={loading}
          onClick={() => {
            del(item);
          }}
        />
        <Button type="blue" label="Cancel" onClick={close} leftMargin="modal" />
      </CenteredButtons>
    </PageCard>
  );
};

export default Delete;
