import Card from "components/card";
import NoAuthCentered from "layouts/auth/types/NoAuthCentered";
import { Link } from "react-router-dom";

function SignUpSuccess() {

  return (
    <NoAuthCentered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] h-max mx-2.5 md:mx-auto mt-12 mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="text-4xl font-bold text-blue-900 dark:text-white">
            Sign Up
          </h3>
          <p className="mt-[10px] ml-1 text-base text-[#636e91]">
            Thank you! Your account has been created! Please check your email and click the confirmation link.
          </p>

          <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:!bg-navy-700">
            <Link
              className="linear text-center mt-4 w-full rounded-xl bg-blue-900 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              to="/"
            >
              Go to login page!
            </Link>
          </div>

        </Card>
      }
    />
  );
}

export default SignUpSuccess;
