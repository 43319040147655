import { PageCard } from 'components/card/PageCard';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import useDelete from 'hooks/useDelete';
import CenteredButtons from '../base/CenteredButtons';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';
import { useEffect } from 'react';

interface DeleteProps {
  close?: () => void;
  item: BusinessTranscript;
}

const Delete = ({ close, item }: DeleteProps) => {
  const { del, deleted, loading } = useDelete<BusinessTranscript>();

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted, close]);

  return (
    <PageCard>
      <Header text="Delete Transcript" />

      <p>
        Are you sure you want to delete <b>{item.title}</b>?
      </p>

      <CenteredButtons modal>
        <Button
          type="red"
          label="Delete"
          loading={loading}
          onClick={() => {
            del(item);
          }}
        />
        <Button type="blue" label="Cancel" onClick={close} leftMargin='modal' />
      </CenteredButtons>
    </PageCard>
  );
};

export default Delete;
