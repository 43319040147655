import { ApiResource } from 'apiplatform/utils/types';
import { useState } from 'react';

const useStream = <TypeRequest extends ApiResource>(iri: string) => {
  const [streaming, setStreaming] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [content, setContent] = useState('');
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  return {
    streaming,
    content,
    completed,
    abort: () => {
      abortController.abort();
      setStreaming(() => false);
      setCompleted(() => false);
      setContent(() => '');
    },
    stream: (requestParameters: Partial<TypeRequest>) => {
      const newAbortController = new AbortController();
      setAbortController(() => newAbortController);
      setStreaming(() => true);

      const storage = localStorage.getItem('user');

      const headers = {
        'Content-Type': 'application/json',
        Authorization: '',
      };

      if (storage !== 'null') {
        const user = JSON.parse(storage);
        headers['Authorization'] = `Bearer ${user.token}`;
      }

      setContent(() => '');

      fetch(process.env.REACT_APP_API_URL + iri, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestParameters),
        signal: newAbortController.signal,
      })
        .then((response) => response.body)
        .then((rb) => {
          const reader = rb.getReader();

          return new ReadableStream({
            start(controller) {
              function push() {
                reader
                  .read()
                  .then(({ done, value }) => {
                    if (done) {
                      setStreaming(() => false);
                      setCompleted(() => true);
                      controller.close();
                      return;
                    }
                    controller.enqueue(value);
                    setContent(
                      (content) => content + new TextDecoder().decode(value)
                    );
                    push();
                  })
                  .catch(() => {});
              }

              push();
            },
          });
        })
        .catch(() => {});
    },
  };
};

export default useStream;
