import Card from 'components/card';
import TooltipHorizon from 'components/tooltip';
import IconButton from 'components/fields/IconButton';
import { HiOutlineSwitchVertical } from 'react-icons/hi';
import { PropsWithChildren, useEffect, useState } from 'react';
import UnstyledList from './Table';
import { useParams, useSearchParams } from 'react-router-dom';
import useRetrieve from 'hooks/useRetrieve';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { MdClear } from 'react-icons/md';

interface SwitcherProps extends PropsWithChildren {
  targetPath?: string;
  hide?: boolean;
}

const Switcher = ({ children, targetPath, hide }: SwitcherProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState<boolean>(false);

  const seachTitle = searchParams.get('title');
  const [filterTitle, setFilterTitle] = useState(seachTitle || '');

  const { ctid } = useParams();
  const { loading, retrieved } = useRetrieve<ContentTheme>(
    `/businesses/content_themes/${ctid}`
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      searchParams.set('title', filterTitle);
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [filterTitle, setSearchParams, searchParams]);

  useEffect(() => {
    setFilterTitle(seachTitle ?? '');
  }, [seachTitle, setFilterTitle]);

  return (
    <>
      {!hide && (
        <Card extra="w-full relative h-full p-4 mb-5 sm:overflow-x-auto text-right">
          {!opened && (
            <div className="grid grid-cols-2 grid-rows-1">
              <div
                className="ml-[16px] pt-2 text-left row-start-1"
                onClick={() => {
                  setOpened((state) => {
                    return !state;
                  });
                }}
              >
                <TooltipHorizon
                  content={
                    <>
                      Click to choose <b>Theme</b> for your new <b>Media</b>
                    </>
                  }
                >
                  <span
                    className={`leading-[29px] ${opened ? 'opacity-25' : ''}`}
                  >
                    Theme:{' '}
                    {searchParams.get('allThemes') ? (
                      <b>All Themes</b>
                    ) : loading && retrieved['@id'] === '' ? (
                      '...'
                    ) : (
                      <b>{retrieved.name}</b>
                    )}
                  </span>
                </TooltipHorizon>
                <span className="left[-8px] absolute top-[26px] inline-block h-[29px] w-[29px]">
                  <IconButton>
                    <HiOutlineSwitchVertical />
                  </IconButton>
                </span>
              </div>
              {/* <div className="px-4">
                <UnstyledList
                  mode="switcher"
                  targetPath={targetPath}
                  setOpened={setOpened}
                  opened={opened}
                />
              </div> */}
              <div className="relative row-start-1 cursor-pointer justify-end pr-4">
                <input
                  type="text"
                  value={filterTitle}
                  onChange={(event) => setFilterTitle(event.target.value)}
                  placeholder="Filter by Title..."
                  className="is-valid mt-[6px] !inline-block flex h-[37px] w-96 items-center justify-center rounded-[8px] border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                />
                <TooltipHorizon
                  content={
                    <>
                      Click here to <b>clear</b> the <b>filter</b>
                    </>
                  }
                >
                  <span
                    onClick={() => {
                      setFilterTitle('');
                    }}
                  >
                    <IconButton
                      extra={`relative top-[9px] ${
                        filterTitle.length > 0
                          ? 'bg-red-500 hover:bg-red-600'
                          : ''
                      }`}
                    >
                      <MdClear />
                    </IconButton>
                  </span>
                </TooltipHorizon>
              </div>
            </div>
          )}

          <div className="px-4">
            <UnstyledList
              mode="switcher"
              targetPath={targetPath}
              setOpened={setOpened}
              opened={opened}
            />
          </div>
        </Card>
      )}
      <div
        className={opened ? 'pointer-events-none opacity-75 blur-[1px]' : ''}
      >
        {children}
      </div>
    </>
  );
};

export default Switcher;
