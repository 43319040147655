import { useSearchParams } from 'react-router-dom';

interface IUseCollectionUri {
  url: string;
  page: string;
  perPage: string;
  sortOrder: string;
  sortBy: string;
  generateUrl: (
    page: string,
    perPage: string,
    sortBy: string,
    sortOrder: string
  ) => string;
}

interface IUseCollectionUrlOptions {
  defaultSortBy?: string;
  defaultSortOrder?: string;
}

function useCollectionUrl(
  iri: string,
  options?: IUseCollectionUrlOptions
): IUseCollectionUri {
  const [searchParams] = useSearchParams();

  let page = searchParams.get('page');
  if (!page) {
    page = '1';
  }

  let perPage = searchParams.get('perPage');
  if (!perPage) {
    perPage = '10';
  }

  let sortBy = searchParams.get('sortBy') || options?.defaultSortBy || '';
  if (!sortBy) {
    sortBy = '';
  }

  let sortOrder =
    searchParams.get('sortOrder') || options?.defaultSortOrder || '';
  if (!sortOrder) {
    sortOrder = '';
  }

  let sort = '';
  if (sortBy !== '' && sortOrder !== '') {
    sort = `&order[${sortBy}]=${sortOrder}`;
  }

  let filterByTitle = searchParams.get('title');
  if (!filterByTitle) {
    filterByTitle = '';
  }

  let allThemes = searchParams.get('allThemes');
  if (allThemes) {
    iri = iri.replace('contentTheme', 'noContentTheme');
  }

  return {
    url: `${iri}${
      iri.includes('?') ? '&' : '?'
    }page=${page}&perPage=${perPage}${sort}&title=${filterByTitle}`,
    page: page,
    perPage: perPage,
    sortBy: sortBy,
    sortOrder: sortOrder,
    generateUrl: (
      page: string,
      perPage: string,
      sortBy: string,
      sortOrder: string
    ) => {
      return `?page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&title=${filterByTitle}${
        allThemes ? '&allThemes=1' : ''
      }`;
    },
  };
}

export default useCollectionUrl;
