import TimelineItem from 'components/dataDisplay/TimelineItem';
import { PagedCollection } from '../../../../../../apiplatform/interfaces/Collection';
import { BusinessArticle } from '../../../../../../apiplatform/interfaces/BusinessArticle';
import Loader from '../../../../../../apiplatform/components/base/Loader';
import moment from 'moment';

interface TimelineProps {
  articlesList: PagedCollection<BusinessArticle>;
  loading: boolean;
}

interface TimeLineDate {
  day: string;
  weekday: string;
  hours: string;
}

const generateTimeLineDate = (datestr: string): TimeLineDate => {
  const date = moment(datestr);

  return {
    day: date.format('DD'),
    weekday: date.format('dddd').substring(0, 3),
    hours: date.format('MM/DD/YYYY hh:mm A'),
  };
};

const TimeLine: React.FC<TimelineProps> = ({ articlesList, loading }) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        articlesList && (
          <>
            <div className="">
              <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Upcoming
              </h4>
            </div>

            <div className="mt-[15px]">
              {articlesList['hydra:member'] &&
                articlesList['hydra:member'].length === 0 && (
                  <span className="text-[#636e91]">No upcoming events.</span>
                )}
              {articlesList['hydra:member'] &&
                articlesList['hydra:member'].map((article, key) => {
                  const { day, weekday, hours } = generateTimeLineDate(
                    article.scheduledAt
                  );

                  return (
                    <TimelineItem
                      mb="mb-[12px]"
                      key={key}
                      title={article.title}
                      day={day.toString()}
                      weekday={weekday}
                      hours={hours}
                    />
                  );
                })}
            </div>
          </>
        )
      )}
    </>
  );
};

export default TimeLine;

export { generateTimeLineDate };
