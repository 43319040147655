import { IoTriangle } from 'react-icons/io5';
import { UI } from './types';

interface EditorBarProps {
  ui: UI;
  width: number;
}

const EditorBar = ({ ui, width }: EditorBarProps) => {
  return (
    <div ref={ui.fullBarRef} className="relative h-[40px] w-full bg-gray-700">
      <div
        ref={ui.progressBarRef}
        className="pointer-events-none absolute bottom-0 left-0 top-0 w-0 bg-gray-800"
      ></div>
      <div
        ref={ui.leftCropBg}
        className="absolute bottom-0 left-0 top-0 w-0 bg-white opacity-50"
      ></div>
      <div
        ref={ui.rightCropBg}
        className="absolute bottom-0 right-0 top-0 w-0 bg-white opacity-50"
      ></div>
      <div
        ref={ui.leftCropRef}
        className="absolute bottom-[-10px] left-0 top-0 w-[1px] cursor-col-resize bg-gray-900"
      >
        <IoTriangle className="absolute bottom-[-5px] left-[-7px] text-[15px] text-gray-900" />
      </div>
      <div
        ref={ui.rightCropRef}
        className="absolute bottom-[-10px] top-0 w-[1px] cursor-col-resize bg-gray-900"
        style={{ left: `${width - 1}px` }}
      >
        <IoTriangle className="absolute bottom-[-5px] left-[-7px] text-[15px] text-gray-900" />
      </div>
      <div
        ref={ui.timeStartRef}
        className="bg-white-100 pointer-events-none absolute left-[12px] top-[50%] mt-[-6px] select-none text-[12px] font-medium leading-[12px] text-white"
      >
        00:00
      </div>
      <div
        ref={ui.timeCurrentRef}
        className="bg-white-100 pointer-events-none absolute left-[50%] top-[50%] ml-[-50px] mt-[-6px] w-[100px] select-none text-center text-[12px] font-medium leading-[12px] text-white"
      >
        00:00
      </div>
      <div
        ref={ui.timeEndRef}
        className="bg-white-100 pointer-events-none absolute right-[12px] top-[50%] mt-[-6px] select-none text-[12px] font-medium leading-[12px] text-white"
      >
        00:00
      </div>
    </div>
  );
};

export default EditorBar;
