import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiResource } from 'apiplatform/utils/types';
import axios from 'axios';
import { buildQueryKeys } from 'hooks';

const useRetrieve = <IResource extends ApiResource>(
  iri: string,
  initialData?: IResource,
  useQueryOptions?: Partial<UseQueryOptions<IResource>>
) => {
  const extraOptions = useQueryOptions ? useQueryOptions : {};

  const {
    data: retrieved,
    isFetching: loading,
    error,
  } = useQuery({
    queryKey: buildQueryKeys(iri),
    queryFn: async (): Promise<IResource> => {
      try {
        return (await axios.get(process.env.REACT_APP_API_URL + iri)).data;
      } catch (e) {
        throw new Error('Unable to retrieve resource!');
      }
    },
    retry: extraOptions?.retry !== undefined ? extraOptions.retry : 1,
    initialData: initialData ? initialData : ({ '@id': '' } as IResource),
    refetchOnWindowFocus: false,
    ...extraOptions,
  });

  return { retrieved, loading, error };
};

export default useRetrieve;
