import React from 'react';
import { PageCard } from '../components/card/PageCard';
import CenteredButtons from '../apiplatform/components/base/CenteredButtons';
import Button from '../components/fields/Button';

interface ContentStatusProps {
  setArticleStatus: (newStatus: 'sent' | 'new') => void;
  close?: () => void; // Optional close function
}

const ContentStatus = ({
  setArticleStatus,
  close,
}: ContentStatusProps) => {
  return (
    <PageCard>
      <p className="pt-12 pb-6">
        You can change the status displayed in the Queue for the content below:
      </p>

      <CenteredButtons modal>
        <Button
          type="blue"
          label="Set Status to Published"
          onClick={() => {
            setArticleStatus('sent');
            if (close) close();
          }}
        />
        <Button
          type="blue"
          label="Set Status to Un-Published"
          leftMargin
          onClick={() => {
            setArticleStatus('new');
            if (close) close();
          }}
        />
      </CenteredButtons>
    </PageCard>
  );
};

export default ContentStatus;
