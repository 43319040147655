import ErrorAlert from 'components/alert/ErrorAlert';
import Loader from '../base/Loader';
import Update from './Update';
import { Business } from 'apiplatform/interfaces/Business';
import useRetrieve from 'hooks/useRetrieve';
import { PageCard } from 'components/card/PageCard';

interface ShowProps {
  close?: () => void;
  business: Business;
}

const Show = ({ business, close }: ShowProps) => {
  const { retrieved, loading, error } = useRetrieve<Business>(business['@id']);

  return (
    <PageCard innerPaddingOff={true}>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorAlert message={error.message} />
      ) : (
        <Update close={close} retrieved={retrieved} />
      )}
    </PageCard>
  );
};

export default Show;
