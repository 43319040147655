import React from 'react';
import {
  FaLinkedin,
  FaWhatsapp,
  FaCopy,
  FaPinterest,
  FaTelegram,
} from 'react-icons/fa';
import { FaFacebook, FaTumblr, FaXTwitter } from 'react-icons/fa6';
import { SocialAppTemplate } from './SocialAppTemplate';
import { FaRedditAlien } from 'react-icons/fa';

interface SocialAppsProps {
  tweet: string;
  url?: string;
}

export const SocialApps: React.FC<SocialAppsProps> = ({ tweet, url }) => {
  return (
    <div className="flex w-72 overflow-x-scroll pb-4">
      <SocialAppTemplate
        message="X"
        url={false}
        link={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          tweet
        )}`}
        extra={`active:bg-gray-950 bg-[#000000]  hover:bg-[#000000d6] `}
      >
        <FaXTwitter size={24} className="inline-block" />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="LinkedIn"
        url={false}
        link={`https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(
          tweet
        )}`}
        extra="bg-[#0077B5]   hover:bg-[#005D8E] active:bg-[#005D8E] dark:bg-[#0077B5] dark:text-white 
        dark:hover:bg-[#005D8E] dark:active:bg-[#005D8E]"
      >
        <FaLinkedin size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message={`WhatsApp`}
        url={false}
        link={`https://api.whatsapp.com/send/?text=${encodeURIComponent(
          tweet
        )}`}
        extra=" bg-[#25D366]   hover:bg-[#23c05d] active:bg-[#23c05d] dark:bg-[#25D366] dark:text-white 
            dark:hover:bg-[#23c05d] dark:active:bg-[#23c05d]"
      >
        <FaWhatsapp size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="Reddit"
        url={false}
        link={`https://www.reddit.com/submit?url=${encodeURIComponent(tweet)}`}
        extra=" bg-[#FF4500] hover:bg-[#ff3c00dc] active:bg-[#ff3c00dc] dark:bg-[#FF4500] dark:text-white 
        dark:hover:bg-[#ff3c00dc] dark:active:bg-[#ff3c00dc]"
      >
        <FaRedditAlien size={24} />
      </SocialAppTemplate>

      {/* <SocialAppTemplate
        message="Instagram"
        link={`https://www.instagram.com/=${encodeURIComponent(tweet)}`}
        extra="bg-gradient-to-br from-purple-700 to-yellow-500 ease-out hover:from-pink-500
            hover:to-purple-700 active:from-pink-500 active:to-purple-700"
      >
        <FaInstagram size={24} />
      </SocialAppTemplate> */}
      <SocialAppTemplate
        message="Facebook"
        url={url}
        link={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&source_surface=external_reshare&display=popup`}
        extra="   bg-[#1877F2]   hover:bg-[#236ed1] active:bg-[#236ed1] dark:bg-[#1877F2] dark:text-white 
          dark:hover:bg-[#236ed1] dark:active:bg-[#236ed1]"
      >
        <FaFacebook size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="Tumblr"
        url={url}
        link={`https://www.tumblr.com/widgets/share/tool/preview?posttype=text&content=${encodeURIComponent(
          url
        )}&caption=`}
        extra=" bg-[#35465d]   hover:bg-[#2b394b] active:bg-[#2b394b] dark:bg-[#35465d] dark:text-white 
      dark:hover:bg-[#2b394b] dark:active:bg-[#2b394b]"
      >
        <FaTumblr size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="Pinterest"
        url={url}
        link={`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
          url
        )}
        &description=${encodeURIComponent(tweet)}`}
        extra="bg-[#BD081C] hover:bg-[#a32231] active:bg-[#a32231] dark:bg-[#BD081C] dark:text-white 
      dark:hover:bg-[#a32231] dark:active:bg-[#a32231]"
      >
        <FaPinterest size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="Telegram"
        url={url}
        link={`https://t.me/share/url?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(tweet)}`}
        extra="bg-[#0088cc] hover:bg-[#0b72a5] active:bg-[#0b72a5] dark:bg-[#0088cc] dark:text-white 
      dark:hover:bg-[#0b72a5] dark:active:bg-[#0b72a5]"
      >
        <FaTelegram size={24} />
      </SocialAppTemplate>
      <SocialAppTemplate
        message="Copy Text"
        copyText
        text={tweet}
        lastChild
        extra="bg-gray-700 hover:bg-gray-400 active:bg-gray-400 dark:bg-[#FF4500] dark:text-white 
        dark:hover:bg-gray-400 dark:active:bg-gray-400"
      >
        <FaCopy size={24} />
      </SocialAppTemplate>
    </div>
  );
};
