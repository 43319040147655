interface SmallSpinnerProps {
  className?: string
}

const SmallSpinner = ({ className }: SmallSpinnerProps) => {
  return <span className={"border-black h-5 w- border-1 inline-block overflow-hidden " + (className ? className : '')}>
    <span className="border-gray-300 h-5 w-5 inline-block animate-spin rounded-full border-4 border-t-[#636e91]" />
  </span>
}

export default SmallSpinner;