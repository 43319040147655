import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';
import { SubmissionError, FormProps } from '../../utils/types';
import Submit from '../base/form/Submit';
import CenteredButtons from '../base/CenteredButtons';
import { BusinessArticleTitle } from 'apiplatform/interfaces/BusinessArticleTitle';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<BusinessArticleTitle>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessArticleTitle>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof BusinessArticleTitle]) {
          return;
        }
        setError(errorPath as keyof BusinessArticleTitle, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<BusinessArticleTitle> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Field
        register={register}
        name="business"
        placeholder=""
        type="hidden"
        errors={errors}
      />
      <Field
        register={register}
        name="contentTheme"
        placeholder=""
        type="hidden"
        errors={errors}
      />
      {initialValues.businessTranscript && (
        <Field
          register={register}
          name="businessTranscript"
          placeholder=""
          type="hidden"
          errors={errors}
        />
      )}

      <div className="max-h-[500px] px-4">
        <Field
          register={register}
          name="title"
          placeholder="Article Title"
          type="text"
          required
          errors={errors}
        />
      </div>
      <CenteredButtons modal>
        <Submit label={label} loading={loading} />
        {footer}
      </CenteredButtons>
    </form>
  );
};

export default Form;
