import Form from './Form';
import ErrorAlert from 'components/alert/ErrorAlert';
import useUpdate from 'hooks/useUpdate';
import { Business } from 'apiplatform/interfaces/Business';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import { useEffect } from 'react';

interface UpdateProps {
  close?: () => void;
  retrieved: Business;
}

const Update = ({ close, retrieved }: UpdateProps) => {
  const {
    updated,
    update,
    loading: updateLoading,
    error: updateError,
  } = useUpdate<Business>();

  useEffect(() => {
    if (updated) {
      close();
    }
  }, [updated, close]);

  return (
    <>
      <div className="px-4">
        <Header text="Update Business" />

        {updateError && <ErrorAlert message={updateError.message} />}
      </div>

      <Form
        onSubmit={(values) => {
          update(retrieved, values);
        }}
        error={updateError}
        label="Update"
        loading={updateLoading}
        initialValues={retrieved}
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
      />
    </>
  );
};

export default Update;
