import Loader from 'apiplatform/components/base/Loader';
import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { PagedCollection } from 'apiplatform/interfaces/Collection';
import { ReactNode } from 'react';
import DataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { Navigate, useNavigate } from 'react-router-dom';
import DataTableNoRecordsWrapper from './DataTableNoRecordsWrapper';

interface DataTableViewProps<T> {
  columns: TableColumn<T>[];
  retrieved: PagedCollection<T> | null;
  noDataComponent?: ReactNode;
  noRowsPerPage?: boolean;
  loading?: boolean;
  noPagination?: boolean;
}

const DataTableView = <T,>({
  columns,
  retrieved,
  loading,
  noDataComponent,
  noRowsPerPage,
  noPagination,
}: DataTableViewProps<T>) => {
  const items = (retrieved && retrieved['hydra:member']) || [];
  const navigate = useNavigate();

  const isLoading =
    '@id' in retrieved && retrieved['@id'] !== '' ? false : loading;

  const { page, perPage, sortBy, sortOrder, generateUrl } =
    useCollectionUrl('');

  const tableStyles: TableStyles = {
    rows: {
      style: {
        '&:not(:last-of-type)': {
          borderBottomWidth: 0,
        },
        '&:hover': {
          backgroundColor: '#f9f9f9',
          borderRadius: 15,
        },
      },
    },
    headRow: {
      style: {
        borderBottomWidth: 0,
        marginBottom: 10,
      },
    },
    pagination: {
      style: {
        borderWidth: 0,
        marginTop: 10,
      },
    },
  };

  return retrieved &&
    retrieved['hydra:member'] &&
    retrieved['hydra:member'].length === 0 &&
    Number(page) > 1 ? (
    <Navigate to="?page=1" />
  ) : (
    <DataTable
      columns={columns}
      data={items}
      highlightOnHover={false}
      customStyles={tableStyles}
      responsive
      progressPending={isLoading}
      persistTableHead={true}
      noDataComponent={
        noDataComponent ? (
          <DataTableNoRecordsWrapper>
            {noDataComponent}
          </DataTableNoRecordsWrapper>
        ) : undefined
      }
      progressComponent={<Loader />}
      pagination={!noPagination}
      onSort={(column, order) => {
        navigate(generateUrl(page, perPage, `${column.id}`, `${order}`));
      }}
      // noDataComponent={<>Error</>}
      paginationServer
      paginationComponentOptions={{
        noRowsPerPage: noRowsPerPage === undefined ? false : noRowsPerPage,
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20]}
      paginationPerPage={Number(perPage)}
      paginationTotalRows={
        retrieved && retrieved['hydra:totalItems']
          ? retrieved['hydra:totalItems']
          : 0
      }
      onChangeRowsPerPage={(rowsPerPage) => {
        navigate(generateUrl(page, `${rowsPerPage}`, sortBy, sortOrder));
      }}
      onChangePage={(newPage) => {
        navigate(generateUrl(`${newPage}`, perPage, sortBy, sortOrder));
      }}
      className="w-full"
    />
  );
};

export default DataTableView;
