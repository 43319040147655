import { PageCard } from 'components/card/PageCard';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelectedVideo } from 'views/main/dashboards/default/components/Video/SelectedVideoContext';
import { Subpage } from '../List';
import useRetrieve from 'hooks/useRetrieve';
import MediaSelector from '../MediaSelector';
import Loader from '../../base/Loader';
import { BusinessTranscriptSuggestion } from 'apiplatform/interfaces/BusinessTranscriptSuggestion';
import { PagedCollection } from 'apiplatform/interfaces/Collection';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { MdClose, MdKeyboardArrowDown, MdKeyboardReturn } from 'react-icons/md';
import TooltipHorizon from 'components/tooltip';
import IconButton from 'components/fields/IconButton';
import SuggestionVideo from './SuggestionVideo';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';

interface SuggestionsProps {
  subpage: Subpage;
  setSubpage: Dispatch<SetStateAction<Subpage>>;
}

const Suggestions = ({ subpage, setSubpage }: SuggestionsProps) => {
  const { retrieved, loading } = useRetrieve<
    PagedCollection<BusinessTranscriptSuggestion>
  >(`${subpage.transcriptId}/suggestions`);

  const { retrieved: transcript } = useRetrieve<BusinessTranscript>(
    subpage.transcriptId
  );

  const [selectedVideo] = useSelectedVideo();
  const [accordionExpanded, setAccordionExpanded] = useState<number>(0);

  return (
    <PageCard>
      <h1 className="relative mb-[2rem] mt-1 text-[22px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
        Media suggestions for: {subpage.title}
        <span
          className="absolute right-0 top-[2px]"
          onClick={() => {
            setSubpage({ type: 'none' });
          }}
        >
          <TooltipHorizon content="Close">
            <IconButton extra="bg-red-500 hover:bg-red-600">
              <MdClose />
            </IconButton>
          </TooltipHorizon>
        </span>
      </h1>

      {selectedVideo && selectedVideo.transcriptId === subpage.transcriptId ? (
        loading ? (
          <Loader />
        ) : (
          <Accordion
            allowMultipleExpanded={false}
            allowZeroExpanded={false}
            preExpanded={[accordionExpanded]}
          >
            {retrieved['hydra:member'].map((suggestion, key) => (
              <AccordionItem uuid={key} key={key}>
                <AccordionItemHeading
                  className="pr-6"
                  onClick={() => {
                    setAccordionExpanded(key);
                  }}
                >
                  <AccordionItemButton className="flex items-center justify-between py-[17px]">
                    <p className="font-bold text-navy-700">
                      {suggestion.title}
                    </p>
                    {accordionExpanded !== key ? (
                      <MdKeyboardArrowDown className="text-lg text-[#636e91]" />
                    ) : (
                      <MdKeyboardReturn className="text-lg text-[#636e91]" />
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {accordionExpanded === key ? (
                    <SuggestionVideo
                      video={selectedVideo.file}
                      startSeconds={suggestion.timestampStart / 1000}
                      endSeconds={suggestion.timestampEnd / 1000}
                      title={suggestion.title}
                    />
                  ) : null}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )
      ) : (
        <>
          <MediaSelector subpage={subpage} />
          {transcript && (
            <div className="text-center">
              We do not store the source media files to speed up the process.
              <br />
              Please select a file used to generate this transcript
              <br />
              {transcript.originalFileName && (
                <>
                  Original file name: &raquo;{' '}
                  <b>{transcript.originalFileName}</b>
                </>
              )}
            </div>
          )}
        </>
      )}
    </PageCard>
  );
};

export default Suggestions;
