import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Spinner from 'components/spinner/Spinner';
import { useState, useEffect, useMemo } from 'react';
import { useAuthContext } from 'components/auth/AuthProvider';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NoAuthCentered from 'layouts/auth/types/NoAuthCentered';
import { decodeBase64 } from 'components/utils/base64Url';

function CompleteRegistration() {
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [stateForm, setStateForm] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const auth = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const handleChangePasswordConfirm = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setPasswordConfirm(event.target.value);

  const emailParam = useMemo<string | null>(
    () => decodeBase64(params?.email),
    [params]
  );
  const tokenParam = useMemo<string | null>(
    () => searchParams.get('token'),
    [searchParams]
  );

  useEffect(() => {
    if (!tokenParam || !emailParam || auth.user !== null) {
      navigate('/signin');
    } else {
      setEmail(emailParam);
    }
  }, [emailParam, tokenParam, navigate, auth.user, setEmail]);

  const handleCompleteRegistration = async () => {
    if (password !== passwordConfirm) {
      setError('Passwords do not match');
      setStateForm('error');
      return;
    }

    setLoading(true);
    setError('');

    const url = `${process.env.REACT_APP_API_URL}/users/active-account`;

    await axios
      .post(url, { password: password, token: tokenParam })
      .then((r) => {
        setLoading(false);
        setPassword('');
        setChanged(true);
        auth.setToken(r.data.token, r.data.refreshToken);
      })
      .catch((e) => {
        setLoading(false);
        setPassword('');
        if (e.response.data?.violations) {
          setError(e.response.data?.violations[0].message);
        } else {
          setError('Your link is expired');
        }
      });
  };

  return (
    <NoAuthCentered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Complete Registration
          </h3>
          <p className="mb-9 ml-1 text-base text-[#636e91]">
            Set your password to complete your registration!
          </p>
          {!changed && (
            <>
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email"
                placeholder={email}
                id="email"
                type="email"
                disabled
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                state={stateForm}
                onChange={handleChangePassword}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Confirm Password*"
                placeholder="Re-enter your password"
                id="passwordConfirm"
                type="password"
                state={stateForm}
                onChange={handleChangePasswordConfirm}
              />
              {loading && <Spinner />}
              <small className="text-center text-red-500">{error}</small>
              <button
                className="linear mt-4 w-full rounded-xl bg-blue-900 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleCompleteRegistration}
              >
                Complete Registration
              </button>
            </>
          )}
        </Card>
      }
    />
  );
}

export default CompleteRegistration;
