import React from 'react';
import { PageCard } from '../../components/card/PageCard';
import Header from '../../apiplatform/components/base/Header';
interface ShowProps {
  sendingToWordpressStatus: boolean;
  title: string;
  error: string;
  footer?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}
export const WordpressContent = ({
  sendingToWordpressStatus,
  title,
  error,
  footer,
}: ShowProps) => {
  return (
    <>
      <PageCard>
        <Header text="Publish Now" />

        <div className="linear rounded-xl  px-5 py-3 text-base font-medium">
          Are you sure you want to send the article{' '}
          <span className="font-bold">{title}</span> to WordPress?
        </div>
        {footer}
        {sendingToWordpressStatus !== null ? (
          <div
            className={`mb-4 mt-4 ${
              sendingToWordpressStatus ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {sendingToWordpressStatus
              ? 'The article has been sent to your WordPress website!'
              : error}
          </div>
        ) : null}
      </PageCard>
    </>
  );
}; 
