import EventCalendar from 'components/calendar/EventCalendar';
import Button from '../../../../../components/fields/Button';
import { useEffect, useState } from 'react';
import { BusinessArticle } from '../../../../../apiplatform/interfaces/BusinessArticle';
import { useParams } from 'react-router-dom';
import TimeLine from './components/TimeLine';
import useRetrieve from '../../../../../hooks/useRetrieve';
import { PagedCollection } from '../../../../../apiplatform/interfaces/Collection';
import moment from 'moment';
import { PageCard } from 'components/card/PageCard';
import TimePicker from './components/TimePicker';
import useCreate from 'hooks/useCreate';
import { ScheduleArticleSending } from 'apiplatform/interfaces/ScheduleArticleSending';

interface CalendarProps {
  article?: BusinessArticle;
  close?: () => void;
}

interface CalendarEventDateTime {
  date: string;
  hour: string;
  minute: string;
  period: string;
}

const Calendar: React.FC<CalendarProps> = ({ article, close }) => {
  const today = new Date();
  const { id } = useParams<{ id: string; ctid: string }>();

  const { retrieved, loading } = useRetrieve<PagedCollection<BusinessArticle>>(
    `/businesses/${id}/scheduled_articles?scheduledAt[after]=${moment(
      today
    ).format('YYYY-MM-DD')}&order[scheduledAt]=asc&perPage=20`
  );

  const {
    create,
    loading: creating,
    created,
  } = useCreate<ScheduleArticleSending>(
    `/businesses/${id}/schedule_article_sending`
  );

  useEffect(() => {
    if (created) {
      article.scheduledAt = created.time;
    }
  }, [created, article]);

  const [eventDateTime, setEventDateTime] = useState<CalendarEventDateTime>({
    date: today.toLocaleDateString('en-CA'),
    hour: moment(today).add(1, 'hour').format('h'),
    minute: moment(today).add(1, 'hour').format('mm'),
    period: moment(today).add(1, 'hour').format('A'),
  });

  const getSelectedDateTime = () => {
    return moment(
      `${eventDateTime.date} ${eventDateTime.hour}:${eventDateTime.minute} ${eventDateTime.period}`,
      'YYYY-MM-DD h:mm A'
    );
  };

  const isFutureDate = () => {
    const selectedDate = getSelectedDateTime();
    const currentDate = moment(new Date());

    return selectedDate.isAfter(currentDate);
  };

  return (
    <PageCard>
      <div className="grid h-full w-full grid-cols-12 rounded-[25px]">
        <div
          className={`col-span-6 col-start-1 my-4 rounded-[20px] dark:text-white`}
        >
          <EventCalendar
            initialDate={new Date()}
            onDateClick={(date: string) => {
              setEventDateTime({ ...eventDateTime, date: date });
            }}
          />
        </div>

        <div className="col-span-6 col-start-7">
          <div>
            <div className="rounded-2xl pl-8">
              <div className="mt-4 text-base font-medium text-[#636e91]">
                <p className="mb-1 whitespace-nowrap text-navy-700">
                  {article.title.length > 50
                    ? `${article.title.substring(0, 50)}...`
                    : article.title}
                </p>
                {article.scheduledAt !== undefined ? (
                  <p>
                    Scheduled to be published on{' '}
                    {moment(article.scheduledAt).format('MM/DD/YYYY')} at{' '}
                    {moment(article.scheduledAt).format('hh:mm A')}
                  </p>
                ) : (
                  <p>
                    Please choose the date and time for publishing your article
                  </p>
                )}
              </div>

              <div className="grid grid-cols-7">
                <div className="col-span-4">
                  <TimePicker
                    eventDateTime={eventDateTime}
                    setEventDateTime={setEventDateTime}
                  />
                </div>
                <div className="col-span-3">
                  {!isFutureDate() ? (
                    <p className="mt-10 px-2 text-center text-sm font-bold !text-red-500">
                      Please choose a time in the future
                    </p>
                  ) : (
                    <span className="mt-9 block text-3xl font-bold text-blue-900">
                      {moment(eventDateTime.date).format('MM/DD/YYYY')}
                    </span>
                  )}
                </div>
              </div>

              <Button
                type="blue"
                label="Confirm"
                loading={creating}
                disabled={!isFutureDate() || article.scheduledAt !== undefined}
                className="mr-4"
                onClick={() => {
                  create({
                    time: getSelectedDateTime().toISOString(),
                    businessArticle: article['@id'],
                  });
                }}
              />
              <Button type="blue" label="Cancel" onClick={close} />

              <div className="mt-8 h-[360px] overflow-auto ">
                <TimeLine articlesList={retrieved} loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageCard>
  );
};

export default Calendar;
export type { CalendarEventDateTime };
