import { PropsWithChildren } from 'react';

interface IconButtonProps extends PropsWithChildren {
  extra?: string;
  onHoverColor?: string; 
  color?: string; 
  disabled?: boolean;
  complete?: boolean;
}

export const IconButton = ({
  children,
  extra,
  color,
  onHoverColor,
  disabled,
  complete
}: IconButtonProps) => {
  const baseColor = complete ? 'bg-green-600' : color || 'bg-blue-900';
  
  const hoverColor = complete ? 'hover:bg-green-600' : onHoverColor || 'hover:bg-brand-600';

  return (
    <span
      className={`ml-2 inline-block cursor-pointer rounded-lg ${baseColor} ${hoverColor} 
        px-2 py-2 text-[13px] font-medium text-white transition duration-200 
        ${extra || ''} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
    >
      {children}
    </span>
  );
};


export default IconButton;
