import { createRef, useEffect, useState } from 'react';
import Editor from 'views/main/dashboards/default/components/VideoEditor/Editor';
import VideoTrimmer from './VideoTrimmer';

interface SuggestionVideoProps {
  video: File;
  width?: number;
  startSeconds: number;
  endSeconds: number;
  title: string;
}

export interface TimeFrame {
  startSeconds: number;
  endSeconds: number;
}

const SuggestionVideo = ({
  width,
  video,
  startSeconds,
  endSeconds,
  title,
}: SuggestionVideoProps) => {
  const [timeFrame, setTimeFrame] = useState<TimeFrame>({
    startSeconds,
    endSeconds,
  });

  const [approved, setApproved] = useState<boolean>(false);

  const divRef = createRef<HTMLDivElement>();

  // restart the animation
  useEffect(() => {
    divRef.current?.classList.remove('animate-fade-in');
    void divRef.current?.offsetWidth;
    divRef.current?.classList.add('animate-fade-in');
  }, [timeFrame, divRef]);

  return approved ? (
    <VideoTrimmer timeFrame={timeFrame} file={video} title={title} />
  ) : (
    <div className="animate-fade-in" ref={divRef}>
      <Editor
        video={video}
        timeFrame={timeFrame}
        onLeftClick={() => {
          setTimeFrame((timeFrame) => {
            return {
              ...timeFrame,
              startSeconds: timeFrame.startSeconds - 5,
            };
          });
        }}
        onRightClick={() => {
          setTimeFrame((timeFrame) => {
            return {
              ...timeFrame,
              endSeconds: timeFrame.endSeconds + 5,
            };
          });
        }}
        onApproveClick={(timeFrame) => {
          setApproved(() => {
            return true;
          });
          setTimeFrame(() => {
            return timeFrame;
          });
        }}
        width={width}
      />
    </div>
  );
};

export default SuggestionVideo;
