import { PageCard } from 'components/card/PageCard';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import useDelete from 'hooks/useDelete';
import CenteredButtons from '../base/CenteredButtons';
import { useEffect } from 'react';
import TResource from '../businessarticle/type';
import { BusinessArticle } from '../../interfaces/BusinessArticle';

interface DeleteProps {
  close?: () => void;
  article: TResource;
}

const Delete = ({ close, article }: DeleteProps) => {
  const {
    del,
    loading: loadingArticle,
    deleted,
  } = useDelete<BusinessArticle>();

  useEffect(() => {
    if (deleted) {
      close();
    }
  }, [deleted, close]);

  return (
    <PageCard>
      <Header text="Delete Confirmation" />
      <div className="px-2 ">
        Are you sure you want to delete{' '}
        <span className="font-bold">{article.title}</span> ?
        <div className="pt-2" /> Content already published to your website or
        social media will not be removed
      </div>
      <div />

      <CenteredButtons modal>
        <Button
          type="red"
          label="Delete"
          loading={loadingArticle}
          onClick={() => {
            del(article);
          }}
        />
        <Button type="blue" label="Cancel" onClick={close} leftMargin />
      </CenteredButtons>
    </PageCard>
  );
};

export default Delete;
