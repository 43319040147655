import ErrorAlert from 'components/alert/ErrorAlert';
import Loader from '../base/Loader';
import Update from './Update';
import useRetrieve from 'hooks/useRetrieve';
import { PageCard } from 'components/card/PageCard';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';

interface ShowProps {
  close?: () => void;
  transcript: BusinessTranscript;
}

const Show = ({ transcript, close }: ShowProps) => {
  const { retrieved, loading, error } = useRetrieve<BusinessTranscript>(
    transcript['@id']
  );

  return (
    <PageCard>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorAlert message={error.message} />
      ) : (
        <Update close={close} retrieved={retrieved} />
      )}
    </PageCard>
  );
};

export default Show;
