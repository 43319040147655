import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BusinessWordpressConfig } from '../../apiplatform/interfaces/BusinessWordpressConfig';
import IBusinessArticle from '../../Database/Model/BusinessArticles';
import { BusinessArticle } from '../../apiplatform/interfaces/BusinessArticle';
import TooltipHorizon from 'components/tooltip';
import WordpressButtons from './WordpressButtons';
import { WordpressContent } from './WordpressContent'; 

const WordpressModal: FunctionComponent<{
  article: IBusinessArticle;
  articleScheduled: BusinessArticle;
  wordpress: BusinessWordpressConfig | null;
  schedule: boolean;
  close?: () => void;
}> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [error, setError] = useState<string>('');
  const [sendingToWordpress, setSendingToWordpress] = useState<boolean>(false);
  const [sendingToWordpressStatus, setSendingToWordpressStatus] = useState<
    boolean | null
  >(null);

  return (
    <>
      <TooltipHorizon
        content={
          props.wordpress && props.wordpress['@id'] === '' ? (
            <>
              Please Setup <b>CMS Integration</b> first
            </>
          ) : null
        }
      />

      <WordpressContent
        sendingToWordpressStatus={sendingToWordpressStatus ?? false} 
        title={props.article.title}
        error={error}
        footer={
          <WordpressButtons
            articleId={id!}
            article={props.article}
            articleScheduled={props.articleScheduled}
            wordpress={props.wordpress}
            schedule={props.schedule}
            sendingToWordpress={sendingToWordpress}
            sendingToWordpressStatus={sendingToWordpressStatus}
            setSendingToWordpress={setSendingToWordpress}
            setSendingToWordpressStatus={setSendingToWordpressStatus}
            setError={setError}
            error={error}
            onClose={props.close}
          />
        }
      />
    </>
  );
};

export default WordpressModal;
