import { useLocation, useParams } from 'react-router-dom';
import { PagedCollection } from '../../interfaces/Collection';
import DataTableView from 'components/datatable/DataTableView';
import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { Link } from 'react-router-dom';
import useRetrieve from 'hooks/useRetrieve';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { Dispatch, SetStateAction } from 'react';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import TooltipHorizon from 'components/tooltip';
import IconButton from 'components/fields/IconButton';
import { MdAdd, MdClose, MdDelete, MdEdit } from 'react-icons/md';
import Create from './Create';
import Update from './Update';
import Delete from './Delete';
import { FaHandPointUp, FaPaintBrush } from 'react-icons/fa';
import Moment from 'react-moment';

interface TableProps {
  setOpened?: Dispatch<SetStateAction<boolean>>;
  opened?: boolean;
  mode: 'switcher' | 'list';
  targetPath?: string;
}

const Table = ({ setOpened, opened, mode, targetPath }: TableProps) => {
  const { id, ctid } = useParams();
  const { url } = useCollectionUrl(`/businesses/${id}/content_themes`);
  const { retrieved, loading } = useRetrieve<PagedCollection<ContentTheme>>(
    mode === 'switcher'
      ? `/businesses/${id}/content_themes?order[name]=asc`
      : url
  );

  const location = useLocation();

  return (
    (opened || mode === 'list') && (
      <div>
        <DataTableView
          retrieved={retrieved}
          loading={loading}
          noRowsPerPage={mode === 'switcher'}
          columns={[
            {
              name: 'Theme Name',
              id: 'name',
              sortable: mode === 'list',
              cell: (row) => {
                return (
                  <Link
                    to={`/business/${id}/theme/${row.id}/${
                      targetPath === undefined ? 'transcripts' : targetPath
                    }`}
                    className="w-full py-4 text-left text-sm font-bold text-navy-700 dark:text-white"
                    onClick={() => {
                      if (setOpened) {
                        setOpened(false);
                      }
                    }}
                  >
                    {row.name}
                  </Link>
                );
              },
            },
            {
              name: 'Created At',
              id: 'createdAt',
              sortable: mode === 'list',
              width: '160px',
              cell: (row) => (
                <Moment
                  format="MMMM Do YYYY"
                  className="text-navy-700 dark:text-white"
                >
                  {row.createdAt}
                </Moment>
              ),
            },
            {
              name: (
                <div className="w-full text-right">
                  <EmptyModalBox
                    item={
                      <TooltipHorizon content="Add Content Theme">
                        <IconButton>
                          <MdAdd />
                        </IconButton>
                      </TooltipHorizon>
                    }
                  >
                    <Create />
                  </EmptyModalBox>
                  {mode === 'switcher' && (
                    <>
                      <TooltipHorizon content="Manage Themes">
                        <Link to={`/business/${id}/theme/${ctid}/themes`}>
                          <IconButton>
                            <FaPaintBrush />
                          </IconButton>
                        </Link>
                      </TooltipHorizon>
                      <TooltipHorizon
                        content={
                          <>
                            Close <b>Theme</b> selector
                          </>
                        }
                      >
                        <span
                          onClick={() => {
                            setOpened(false);
                          }}
                        >
                          <IconButton extra="bg-red-500 hover:bg-red-600">
                            <MdClose />
                          </IconButton>
                        </span>
                      </TooltipHorizon>
                    </>
                  )}
                </div>
              ),
              width: '160px',
              cell: (row) => (
                <div className="w-full text-right">
                  <TooltipHorizon content="Generate Content for this Theme">
                    <Link
                      to={`/business/${id}/theme/${row.id}/${
                        targetPath === undefined ? 'transcripts' : targetPath
                      }`}
                      onClick={() => {
                        if (setOpened) {
                          setOpened(false);
                        }
                      }}
                    >
                      <IconButton>
                        <FaHandPointUp />
                      </IconButton>
                    </Link>
                  </TooltipHorizon>

                  <EmptyModalBox
                    item={
                      <TooltipHorizon content="Edit">
                        <IconButton>
                          <MdEdit />
                        </IconButton>
                      </TooltipHorizon>
                    }
                  >
                    <Update retrieved={row} />
                  </EmptyModalBox>

                  {!row.defaultTheme && mode === 'list' && (
                    <EmptyModalBox
                      item={
                        <TooltipHorizon content="Delete">
                          <IconButton>
                            <MdDelete />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Delete item={row} />
                    </EmptyModalBox>
                  )}
                </div>
              ),
            },
          ]}
        />
        {location.pathname.includes('queue') && (
          <div className="mb-4 mt-8 text-center">
            <Link
              className="text-brand-500"
              to={'?allThemes=1'}
              onClick={() => {
                setOpened(false);
              }}
            >
              Show media from <b>All Themes</b>
            </Link>
          </div>
        )}
      </div>
    )
  );
};

export default Table;
