import { MutableRefObject, useState } from 'react';
import Button from '../components/fields/Button';
import { Editor as TinyMCEEditor } from 'tinymce';

interface CopyToClipboardProps {
  editorRef: MutableRefObject<TinyMCEEditor>;
  title: string;

  extra?: string;
}

const CopyToClipboard = ({ editorRef, title, extra }: CopyToClipboardProps) => {
  const [copyToClipboardStatus, setCopyToClipboardStatus] = useState<
    'default' | 'complete' | 'error'
  >('default');
  return (
    <>
      <Button
        type={copyToClipboardStatus === 'error' ? 'red' : 'blue'}
        label="Copy to Clipboard"
        leftMargin
        className={` ${extra}`}
        customButton=" rounded-lg px-4 py-1"
        loading={undefined}
        completed={copyToClipboardStatus === 'complete'}
        onClick={() => {
          try {
            const content = editorRef.current?.getContent() || '';
            navigator.clipboard.write([
              new ClipboardItem({
                'text/html': new Blob(
                  ['<h1>' + title + '</h1>\n\n' + content], 
                  { type: 'text/html' }
                ),
              }),
            ]);
            setCopyToClipboardStatus('complete');
          } catch (e) {
            setCopyToClipboardStatus('error');
          }
        }}
      />
    </>
  );
};

export default CopyToClipboard;
