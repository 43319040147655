import { CreateManager } from 'apiplatform/interfaces/Manager';
import { SubmissionError, TError } from 'apiplatform/utils/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ReactElement, useEffect } from 'react';
import InputField from 'components/fields/InputField';
import CenteredButtons from '../base/CenteredButtons';
import Submit from '../base/form/Submit';
import BusinessList from './BusinessList';
import Field from '../base/form/Field';
import RoleList from './RoleList';

interface FormProps<T> {
  onSubmit: (item: Partial<T>) => void;
  initialValues?: Partial<T>;
  label?: string;
  loading?: boolean;
  error?: TError;
  footer?: ReactElement;
}
const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<CreateManager>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateManager>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  const onFormSubmit: SubmitHandler<CreateManager> = (data) => {
    onSubmit({
      ...data,
    });
  };

  const emailInput = register('email');

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <InputField
        label="Email"
        placeholder="Email"
        id="email"
        inputRef={emailInput.ref}
        onChange={emailInput.onChange}
      />
      {/* 
      <Field
        register={register}
        name="business"
        placeholder=""
        type="hidden"
        errors={errors}
      /> */}

      <div>
        <BusinessList register={register('business')} />
      </div>

      <div>
        <RoleList register={register('roles', { setValueAs: (v) => [v] })} />
      </div>

      <CenteredButtons modal>
        <Submit label={label} loading={loading} />
        {footer}
      </CenteredButtons>
    </form>
  );
};
export default Form;
