import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';

import Submit from '../base/form/Submit';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';

import { SubmissionError, FormProps } from '../../utils/types';
import CenteredButtons from '../base/CenteredButtons';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<BusinessTranscript>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessTranscript>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof BusinessTranscript]) {
          return;
        }
        setError(errorPath as keyof BusinessTranscript, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<BusinessTranscript> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Field
        register={register}
        name="business"
        placeholder=""
        type="hidden"
        errors={errors}
      />
      <Field
        register={register}
        name="contentTheme"
        placeholder=""
        type="hidden"
        errors={errors}
      />{' '}
      <Field
        register={register}
        name="title"
        placeholder="Title"
        type="text"
        required
        errors={errors}
      />
      <Field
        register={register}
        name="content"
        placeholder="Transcript"
        type="textarea"
        additionalClassName="h-[300px]"
        required
        errors={errors}
      />
      <Field
        register={register}
        name="timestamps"
        placeholder=""
        type="hidden"
        additionalClassName="h-48"
        errors={errors}
      />
      <Field
        register={register}
        name="summary"
        placeholder={label === 'Create' ? '' : 'Summary & Highlights'}
        type={label === 'Create' ? 'hidden' : 'textarea'}
        additionalClassName="h-48"
        errors={errors}
      />
      <CenteredButtons modal >
        <Submit label={label} loading={loading} />
        {footer}
      </CenteredButtons>
    </form>
  );
};

export default Form;
