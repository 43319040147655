import { useEffect, useState } from 'react';

import MainLayout from 'layouts/main';

interface ThemedLayoutProps {
  showMainLayout: boolean;
}

const ThemedLayout = ({ showMainLayout }: ThemedLayoutProps) => {
  // Create a new context
  const [themeApp, setThemeApp] = useState<any>({
    '--background-100': '#FFFFFF',
    '--background-900': '#070f2e',
    '--shadow-100': 'rgba(112, 144, 176, 0.08)',
    '--color-50': '#E9E3FF',
    '--color-100': '#C0B8FE',
    '--color-200': '#A195FD',
    '--color-300': '#8171FC',
    '--color-400': '#7551FF',
    '--color-500': '#422AFB',
    '--color-600': '#3311DB',
    '--color-700': '#2111A5',
    '--color-800': '#190793',
    '--color-900': '#11047A',
  });
  const [mini, setMini] = useState(false);
  const [navy700, setNavy700] = useState('#1B254B');
  const [blue900, setBlue900] = useState('#00007d');
  const [brand600, setBrand600] = useState('#3311DB');
  const [gray600, setGray600] = useState('#A3AED0');
  const [bg100, setBg100] = useState('#FFFFFF');

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    for (let color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color-600', brand600);
  }, [brand600]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color-blue-900', blue900);
  }, [blue900]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color-navy-700', navy700);
  }, [navy700]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color-[#636e91]', gray600);
  }, [gray600]);

  useEffect(() => {
    document.documentElement.style.setProperty('--background-100', bg100);
  }, [bg100]);

  return (
    <>
      <div className="fixed bottom-0 left-0 !z-50 hidden p-5">
        Colors:
        <br />
        navy-700:{' '}
        <input
          className="border border-gray-200"
          value={navy700}
          onChange={(event) => {
            setNavy700(event.target.value);
          }}
        />
        <br />
        blue-900:{' '}
        <input
          className="border border-gray-200"
          value={blue900}
          onChange={(event) => {
            setBlue900(event.target.value);
          }}
        />
        <br />
        brand-600:{' '}
        <input
          className="border border-gray-200"
          value={brand600}
          onChange={(event) => {
            setBrand600(event.target.value);
          }}
        />{' '}
        <br />
        [#636e91]:{' '}
        <input
          className="border border-gray-200"
          value={gray600}
          onChange={(event) => {
            setGray600(event.target.value);
          }}
        />{' '}
        <br />
        background:{' '}
        <input
          className="border border-gray-200"
          value={bg100}
          onChange={(event) => {
            setBg100(event.target.value);
          }}
        />
      </div>
      {showMainLayout && (
        <MainLayout
          setMini={setMini}
          mini={mini}
          theme={themeApp}
          setTheme={setThemeApp}
        />
      )}
    </>
  );
};

export default ThemedLayout;
