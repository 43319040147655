import { createContext, useContext } from 'react';
import useAuth, { BusinessPermission, User, UserData } from './useAuth';
import { jwtInterceptor } from './jwtInterceptor';

export interface AuthProviderInterface {
  user: User | null;
  signIn: (data: UserData) => void;
  signOut: () => void;
  getEmail: () => string;
  isAuthorized: () => boolean;
  refreshToken: () => Promise<boolean>;
  setToken: (token: string, refreshToken: string | null) => void;
  getPermissions: () => BusinessPermission[];
  getPermision: (id: number) => BusinessPermission | null;
  isOwner: () => boolean;
  isBusinessOwner: (businessId: number) => boolean;
  isSingleNotOwnerBusiness(): boolean;
  getId: () => number;
}

const AuthContext = createContext<AuthProviderInterface>({
  user: null,
  signIn: () => {},
  signOut: () => {},
  getEmail: () => '',
  isAuthorized: () => false,
  refreshToken: async () => false,
  setToken: () => {},
  getPermissions: () => [],
  getPermision: () => null,
  isOwner: () => false,
  isBusinessOwner: () => false,
  isSingleNotOwnerBusiness: () => false,
  getId: () => 0,
});

const useAuthContext = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuth();
  jwtInterceptor(auth);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { useAuthContext, AuthProvider };
