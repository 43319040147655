import { PropsWithChildren } from 'react';

interface CenteredButtonsProps extends PropsWithChildren {
  extra?: string;  
  leftAlign?:boolean
  modal?:boolean
}

const CenteredButtons = ({ children, extra, leftAlign = false, modal,  }: CenteredButtonsProps) => {
  return     <div className={`inline-block pt-4 ${leftAlign ? '' : 'text-center'} ${modal ? 'flex justify-center items-center' : ''} ${extra}`}>
{children}</div>;
};

export default CenteredButtons;
