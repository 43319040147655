import React from 'react';
import Footer from 'components/footer/FooterAuthCentered';

const NoAuthCentered = (props: { maincard: JSX.Element }) => {
  const { maincard } = props;
  return (
    <div className="flex min-h-screen w-full flex-col self-center justify-self-center overflow-hidden lg:mx-0">
      {/* <FixedPlugin /> */}
      <div className="absolute left-0 right-0 top-0 max-h-[48vh] min-h-[48vh] w-full overflow-hidden bg-gradient-to-br from-blue-700 to-blue-900  bg-cover bg-no-repeat md:mx-auto" />
      {/* bgImage={image} */}
      {maincard}
      <Footer />
    </div>
  );
};

export default NoAuthCentered;
