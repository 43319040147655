import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import generateBlogPostTweet from '../../Request/OpenAi/generateBlogPostTweet';
import IBusinessArticle from '../../Database/Model/BusinessArticles';
import React from 'react';
import { BusinessArticle } from '../../apiplatform/interfaces/BusinessArticle';
import { PageCard } from '../../components/card/PageCard';
import Header from '../../apiplatform/components/base/Header';
import TweetContent from './TweetContent';
import TwitterButtons from './TwitterButtons';

const TwitterModal: FunctionComponent<{
  article: IBusinessArticle;
  articleScheculed: BusinessArticle;
  close?: () => void;
}> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [tweetLoading, setTweetLoading] = useState<boolean>(false);
  //const [error, setError] = useState<string>('');
  const [tweet, setTweet] = useState<string>('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      setTweetLoading(true);
      // setError('');

      generateBlogPostTweet(
        id,
        props.article,
        (response) => {
          setTweetLoading(false);
          setTweet(response);
        },
        () => {
          setTweetLoading(false);
          // setError(error.error);
        }
      );
    }
  }, [id, props.article]);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.articleScheculed.url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <PageCard>
        <Header text="Social Media Share" />

        <TweetContent
          tweetLoading={tweetLoading}
          articleUrl={props.articleScheculed.url}
          copied={copied}
          onCopy={handleCopy}
          tweet={tweet}
        />

        {!tweetLoading && (
          <TwitterButtons
            tweet={tweet}
            articleUrl={props.articleScheculed.url}
            onTweetSave={(cleanedContent) => setTweet(cleanedContent)}
            onClose={props.close}
          />
        )}
      </PageCard>
    </>
  );
};

export default TwitterModal;
