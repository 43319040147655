import { ReactElement } from 'react';

interface LoaderProps {
  title?: ReactElement | string;
  size?: string;
}

const Loader = ({ title, size }: LoaderProps) => {
  return (
    <div className="relative mb-8">
      <div
        className={`
   border-current
   border-r-transparent block
   ${size ? `h-${size} w-${size}` : 'h-8 w-8'}
   animate-spin rounded-full border-4
   border-dotted border-indigo-500
   align-[-0.125em]
   motion-reduce:animate-[spin_1.5s_linear_infinite]
 `}
        role="status"
      />

      {title ? (
        <span className="text-md absolute left-12 top-0 font-medium leading-8 text-blue-900">
          {title}
        </span>
      ) : null}
    </div>
  );
};

export default Loader;
