import { matchPath, useLocation } from 'react-router-dom';
import BusinessBreadcrumb from './BusinessBreadcrumb';

const Breadcrumbs = () => {
  const params = matchPath(
    '/business/:id/theme/:ctid/*',
    useLocation().pathname
  )?.params;

  return params?.id ? (
    <BusinessBreadcrumb id={params.id} ctid={params.ctid} />
  ) : (
    <div className="text-sm">&nbsp;</div>
  );
};

export default Breadcrumbs;
