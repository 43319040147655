import { ReactNode } from 'react';

interface VideoUploadButtonProps {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
  footer?: ReactNode;
}

const VideoUploadButton = ({
  icon,
  title,
  description,
  footer,
}: VideoUploadButtonProps) => {
  return (
    <button className="my-9 mb-4 mr-3 flex h-[300px] w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 pb-2 pt-9 dark:!border-blue-900 lg:h-[250px] lg:pb-0">
      {icon}
      <h4 className="text-sm font-bold text-blue-900 dark:text-white lg:text-xl">
        {title}
      </h4>
      <p className="mb-4 mt-2 text-sm font-medium text-[#636e91]">
        {description}
      </p>
      {footer}
    </button>
  );
};

export default VideoUploadButton;
