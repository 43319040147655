import { PageCard } from 'components/card/PageCard';
import ErrorAlert from 'components/alert/ErrorAlert';
import useCreate from 'hooks/useCreate';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import Form from './Form';
import { BusinessArticleTitle } from 'apiplatform/interfaces/BusinessArticleTitle';
import { useParams } from 'react-router-dom';
import { Subpage } from '../businesstranscript/List';
import { useEffect } from 'react';

interface CreateProps {
  close?: () => void;
  subpage: Subpage;
}

const Create = ({ close, subpage }: CreateProps) => {
  const { id, ctid } = useParams();

  const { created, loading, error, create } = useCreate<BusinessArticleTitle>(
    `/businesses/business_article_titles`
  );

  useEffect(() => {
    if (created) {
      close();
    }
  }, [created, close]);

  return (
    <PageCard innerPaddingOff={true}>
      <div className="px-4">
        <Header text="Create Article Title" />
        {error && <ErrorAlert message={error.message} />}
      </div>

      <Form
        onSubmit={create}
        error={error}
        loading={loading}
        initialValues={{
          business: `/businesses/${id}`,
          contentTheme: `/businesses/content_themes/${ctid}`,
          businessTranscript: subpage.transcriptId
            ? subpage.transcriptId
            : undefined,
        }}
        label="Create"
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
      />
    </PageCard>
  );
};

export default Create;
