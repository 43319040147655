import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import {
  FaCheck,
  FaPause,
  FaPlay,
  FaStepBackward,
  FaStepForward,
  FaStop,
} from 'react-icons/fa';
import { EditorButtonProps, State, UI } from './types';

interface EditorControlsProps extends EditorButtonProps {
  ui: UI;
  state: State;
}

const EditorControls = ({
  ui,
  state,
  onLeftClick,
  onRightClick,
  onApproveClick,
}: EditorControlsProps) => {
  return (
    <div className="mt-4 text-center">
      <TooltipHorizon content="Add 5s on the left">
        <span
          onClick={() => {
            if (onLeftClick) {
              onLeftClick();
            }
          }}
        >
          <IconButton>
            <FaStepBackward />
          </IconButton>
        </span>
      </TooltipHorizon>

      <TooltipHorizon content="Stop">
        <span ref={ui.stopRef}>
          <IconButton>
            <FaStop />
          </IconButton>
        </span>
      </TooltipHorizon>

      <TooltipHorizon content="Play / Pause">
        <span ref={ui.playRef}>
          <IconButton>
            <FaPlay className="play" />
            <FaPause className="pause hidden" />
          </IconButton>
        </span>
      </TooltipHorizon>

      <TooltipHorizon content="Approve & Save">
        <span
          onClick={() => {
            if (onApproveClick) {
              onApproveClick({
                startSeconds: state.leftCropStartTime,
                endSeconds: state.rightCropEndTime,
              });
            }
          }}
        >
          <IconButton complete>
            <FaCheck />
          </IconButton>
        </span>
      </TooltipHorizon>

      <TooltipHorizon content="Add 5s on the right">
        <span
          onClick={() => {
            if (onRightClick) {
              onRightClick();
            }
          }}
          className="mr-2"
        >
          <IconButton>
            <FaStepForward />
          </IconButton>
        </span>
      </TooltipHorizon>
    </div>
  );
};

export default EditorControls;
