import { GenerateBusinessArticle } from 'apiplatform/interfaces/GenerateBusinessArticle';
import IconButton from 'components/fields/IconButton';
import useCreate from 'hooks/useCreate';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { MdPlayArrow } from 'react-icons/md';
import { useParams } from 'react-router-dom';

interface GenerateArticleButtonProps {
  articleId: string;
}

const GenerateArticleButton = ({ articleId }: GenerateArticleButtonProps) => {
  const { id } = useParams();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const { create: generateArticle } = useCreate<GenerateBusinessArticle>(
    `/businesses/${id}/business_article`
  );

  return (
    <span
      onClick={() => {
        setIsClicked(true);
        generateArticle({
          businessArticleTitle: articleId,
        });
      }}
    >
      <IconButton>
        {isClicked ? <FaSpinner className="animate-spin" /> : <MdPlayArrow />}
      </IconButton>
    </span>
  );
};

export default GenerateArticleButton;
