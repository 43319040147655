import { useParams } from 'react-router-dom';
import { PagedCollection } from '../../interfaces/Collection';
import Update from './Update';
import Loader from '../base/Loader';
import ErrorAlert from 'components/alert/ErrorAlert';
import { PageCard } from '../../../components/card/PageCard';
import Button from '../../../components/fields/Button';
import useRetrieve from 'hooks/useRetrieve';
import { BusinessPrompt } from 'apiplatform/interfaces/BusinessPrompt';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Restore from './Restore';
import { useState } from 'react';
import { ExplainerText } from '../base/ExplainerText';
import { titleMapping } from '../../utils/tittleMapping';
import RestorePrompt from './RestorePrompt';
import TooltipHorizon from '../../../components/tooltip';
import TagsTable from './TagsTable';

const List = () => {
  const { id, ctid } = useParams();

  const [key, setKey] = useState(0); // reload prompts after reset to default

  const { retrieved, loading, error } = useRetrieve<
    PagedCollection<BusinessPrompt>
  >(`/businesses/${id}/prompts?contentTheme=${ctid}`);

  const items = (retrieved && retrieved['hydra:member']) || [];

  const orderedItems = Object.keys(titleMapping)
    .map((key) => items.find((item) => item.type === key))
    .filter((item) => item !== undefined);

  return (
    <>
      <ExplainerText
        text={
          <>
            Prompt Settings are unique for each business. The inclusion of
            [tags] allows further customization. A list of available tags is{' '}
            <EmptyModalBox
            width={1000}
              item={
                <TooltipHorizon content="">
                  <p className="cursor-pointer font-semibold  underline">available here.</p>
                </TooltipHorizon>
              }
            >
              <TagsTable />
            </EmptyModalBox>{' '}
            Please note that the option to restore the default prompt will reset
            all prompts.
          </>
        }
      />
      <PageCard>
        {error && <ErrorAlert message={error.message} />}
        {loading && <Loader />}

        <div key={key}>
          {orderedItems.map((item, key) => (
            <Update
              key={key}
              retrieved={item}
              footer={
                <EmptyModalBox
                  item={
                    <Button
                      type="red"
                      label="Restore Default Prompt"
                      className="mx-auto ml-4"
                      buttonType="button"
                    />
                  }
                >
                  <RestorePrompt id={item.id} setKey={setKey} />
                </EmptyModalBox>
              }
            />
          ))}
        </div>

        <EmptyModalBox
          item={
            <Button
              type="red"
              label="Restore all Default Prompts"
              className="mx-auto mt-8"
            />
          }
        >
          <Restore setKey={setKey} />
        </EmptyModalBox>
      </PageCard>
    </>
  );
};

export default List;
