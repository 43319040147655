import { useAuthContext } from 'components/auth/AuthProvider';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const LoginAsUser = () => {
  const [searchParams] = useSearchParams();
  const auth = useAuthContext();

  const loginAsUser = useCallback(() => {
    const token = searchParams.get('token');
    const refreshToken = searchParams.get('refreshToken');

    auth.setToken(token, refreshToken);
  }, [auth, searchParams]);

  useEffect(() => {
    loginAsUser();
  }, [loginAsUser]);

  return <></>;
};

export default LoginAsUser;
