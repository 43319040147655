import { EditorProps, State, UI } from './types';
import EditorControls from './EditorControls';
import EditorBar from './EditorBar';
import { createState } from './utils';
import { useEditor, useUI } from './hooks';
import { FaRegFileAudio } from 'react-icons/fa';

const Editor = ({
  video,
  width,
  timeFrame,
  onLeftClick,
  onRightClick,
  onApproveClick,
}: EditorProps) => {
  const ui: UI = useUI();

  const startSeconds = timeFrame?.startSeconds;

  var state: State = createState(
    width ? width : 750,
    startSeconds && startSeconds > 0 ? startSeconds : 0,
    timeFrame?.endSeconds
  );

  useEditor(state, ui);

  return (
    <div className="h-full w-full pb-[20px]" ref={ui.editorAreaRef}>
      <div className="relative m-auto" style={{ width: `${state.width}px` }}>
        <span className="absolute left-[50%] top-[40%] ml-[-30px] text-[60px]">
          <FaRegFileAudio />
        </span>
        <video
          className="w-full bg-gray-50"
          src={window.URL.createObjectURL(video)}
          ref={ui.videoRef}
        />
        <EditorBar ui={ui} width={state.width} />
        <EditorControls
          ui={ui}
          state={state}
          onLeftClick={onLeftClick}
          onRightClick={onRightClick}
          onApproveClick={onApproveClick}
        />
      </div>
    </div>
  );
};

export default Editor;
