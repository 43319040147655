import { MediaObject } from 'apiplatform/interfaces/MediaObject';
import { PageCard } from 'components/card/PageCard';
import useMedia from 'hooks/useMedia';
import {
  DragEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdFileUpload } from 'react-icons/md';
import { Editor as TinyMCEEditor } from 'tinymce';
import VideoUploadButton from 'views/main/dashboards/default/components/VideoUploadButton';
import Loader from '../base/Loader';
import GenerateImage from './GenerateImage';
import { BusinessArticle } from 'apiplatform/interfaces/BusinessArticle';
import { Image } from './type';
import CenteredButtons from '../base/CenteredButtons';

interface InsertImageProps {
  close?: () => void;
  editorRef: MutableRefObject<TinyMCEEditor>;
  article: BusinessArticle;
  images: Image[];
  setImages: (images: Image[]) => void;
}

const InsertImage = ({
  close,
  editorRef,
  article,
  images,
  setImages,
}: InsertImageProps) => {
  const {
    create: createMedia,
    loading: isCreating,
    created: mediaCreated,
  } = useMedia<MediaObject>('/media_objects', {
    noInvalidate: true,
  });

  const insertImageRan = useRef(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const scrollRef = useRef(null);

  const stopPropagation = (evt: DragEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const dropHandler = async (evt: DragEvent<HTMLDivElement>) => {
    const file = evt.dataTransfer.files[0];
    stopPropagation(evt);
    handleFile(file);
  };

  const selectFile = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = (evt) => {
      if (evt.target instanceof HTMLInputElement) {
        handleFile(evt.target.files[0]);
      }
    };
  };

  const handleFile = (file: File) => {
    createMedia([
      {
        name: 'file',
        blobValue: file,
        filename: file.name,
      },
    ]);
  };

  const onGenerated = (image: Image) => {
    if (images.includes(image)) return;
    setImages([...images, image]);
  };

  const clearImages = () => {
    setImages([]);
  };

  const selectGeneratedImage = async (data: string) => {
    const blob = await fetch(`data:image/jpeg;base64,${data}`).then((res) =>
      res.blob()
    );

    handleFile(new File([blob], 'image.png', { type: 'image/png' }));
  };

  const displayImages = useCallback(() => {
    const ele = scrollRef.current;

    if (ele) {
      ele.scrollTop = ele.scrollHeight;
    }

    return images.map((img) => (
      <div className="mb-2 mr-5" key={img['@id']}>
        <img
          src={`data:image/jpeg;base64,${img.data}`}
          alt="Generated Image"
          width="150"
          height="150"
          className="inline-block"
        />
        <div className="text-center">
          <button
            type="button"
            className="mt-1 rounded-lg bg-brand-500 p-2 text-xs font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={(e) => selectGeneratedImage(img.data)}
            disabled={isCreating || isImageLoading}
          >
            Select
          </button>
        </div>
      </div>
    ));
  }, [images, isCreating, isImageLoading, selectGeneratedImage]);

  useEffect(() => {
    if (mediaCreated && !insertImageRan.current) {
      editorRef.current.execCommand(
        'mceInsertContent',
        false,
        `<img src="${mediaCreated.contentUrl}" alt="" />`
      );
      close();
      insertImageRan.current = true;
    }
  }, [mediaCreated, close, editorRef]);

  return (
    <PageCard>
      {isCreating && (
        <div className="mt-8">
          <Loader title="Your image is being saved, please wait..." />
        </div>
      )}
      {!isCreating &&
        !mediaCreated &&
        images.length === 0 &&
        isImageLoading === false && (
          <>
            <p className="cursor-pointer text-lg font-semibold text-blue-900 dark:text-white">
              Upload your own image
            </p>
            <div
              onDrop={dropHandler}
              onDragEnter={stopPropagation}
              onDragOver={stopPropagation}
              onDragLeave={stopPropagation}
              onClick={selectFile}
            >
              <VideoUploadButton
                title="Select File"
                icon={
                  <MdFileUpload className="text-[80px] text-blue-900 dark:text-white" />
                }
                description={
                  <>
                    supported file types: <b>jpeg gif png</b>
                    <br />
                    max file size: <b>100MB</b>
                    <br />
                    drop your file here or click to select a file
                  </>
                }
              />
            </div>
          </>
        )}
      <div className=" flex py-2" />

      {isImageLoading && (
        <div>
          <p className="text-center">
            Please wait, we are working on your image!
          </p>
        </div>
      )}
      {images.length > 0 && (
        <div className="mt-5 max-h-96 overflow-y-auto" ref={scrollRef}>
          <div className="grid grid-cols-3 gap-1">{displayImages()}</div>
          <CenteredButtons modal>
            <button
              type="button"
              className="mt-1 rounded-lg bg-brand-500 p-2 text-xs font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={clearImages}
              disabled={isCreating || isImageLoading}
            >
              Clear all Images
            </button>
          </CenteredButtons>
        </div>
      )}

      <GenerateImage
        article={article}
        onGenerated={onGenerated}
        setLoading={setIsImageLoading}
        disabled={isCreating}
      />
    </PageCard>
  );
};

export default InsertImage;
