import { ReactNode } from 'react';
import Card from '../../../components/card';

interface ExplainerTextProps {
  text: ReactNode; // Allows both strings and JSX
  leftPadding?: boolean;
}

export const ExplainerText = ({
  text,
  leftPadding = true,
}: ExplainerTextProps) => {
  return (
    <Card
      extra={`container lg:ml-0 mb-5 my-3 rounded-[20px] pr-4 py-4 text-[#636e91] ${
        leftPadding ? 'pl-4' : 'pl-2'
      }`}
    >
      <div className="text-xs lg:text-base">{text}</div>
    </Card>
  );
};
