import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResource } from 'apiplatform/utils/types';
import axios from 'axios';
import { buildQueryKeys } from 'hooks';
import { useState } from 'react';

const useDelete = <IResource extends ApiResource>() => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate,
    error,
    isSuccess: deleted,
  } = useMutation<IResource, Error, string>({
    mutationFn: async (iri) => {
      setLoading(() => true);
      try {
        const response = await axios.delete(
          process.env.REACT_APP_API_URL + iri
        );
        return response.data;
      } catch (e) {
        throw new Error('Unable to delete resource!');
      }
    },
    onSuccess: (resource, iri) => {
      queryClient.invalidateQueries({
        queryKey: [buildQueryKeys(iri)[0]],
      });
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const del = (iri: IResource) => {
    mutate(iri['@id']);
  };

  return { loading, del, error, deleted };
};

export default useDelete;
