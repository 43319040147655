import { PageCard } from 'components/card/PageCard';
import { ExplainerText } from '../base/ExplainerText';
import UnstyledList from './Table';

const List = () => {
  return (
    <>
      <ExplainerText text="Content Themes enable you to establish categories or unique subsets of content that share a common narrative or focus." />
      <PageCard>
        <UnstyledList mode="list" />
      </PageCard>
    </>
  );
};

export default List;
