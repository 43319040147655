import Form from './Form';
import { PageCard } from 'components/card/PageCard';
import ErrorAlert from 'components/alert/ErrorAlert';
import useCreate from 'hooks/useCreate';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

interface CreateProps {
  close?: () => void;
}

const Create = ({ close }: CreateProps) => {
  const { created, loading, error, create } = useCreate<BusinessTranscript>(
    `/businesses/business_transcripts`
  );

  const { id, ctid } = useParams();

  useEffect(() => {
    if (created) {
      close();
    }
  }, [created, close]);

  return (
    <PageCard>
      <Header text="Add Transcript" />
      {error && <ErrorAlert message={error.message} />}

      <Form
        onSubmit={create}
        error={error}
        loading={loading}
        label="Create"
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
        initialValues={{
          business: `/businesses/${id}`,
          contentTheme: `/businesses/content_themes/${ctid}`,
          businessTranscriptStatus: 'ready',
        }}
      />
    </PageCard>
  );
};

export default Create;
