import { ReactElement } from 'react';

export type TError = SubmissionError | Error | null;

export interface ApiResource {
  '@id': string;
}

export interface SubmissionErrors {
  [p: string]: string;
}

export class SubmissionError extends Error {
  private readonly _errors: SubmissionErrors;

  constructor(message: string, errors: SubmissionErrors) {
    super(message);
    this._errors = errors;
  }

  public get errors(): SubmissionErrors {
    return this._errors;
  }
}

export interface FormProps<Resource extends ApiResource> {
  onSubmit: (item: Partial<Resource>) => void;
  initialValues?: Partial<Resource>;
  label?: string;
  loading?: boolean;
  error?: TError;
  footer?: ReactElement;
}
