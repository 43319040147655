import axios from 'axios';
import IBusinessArticle from '../../Database/Model/BusinessArticles';
import IResponseError from '../../Type/IResponseError';

interface IBusinessTweet {
  '@id': string;
  '@type': string;
  '@context': string;
  content: string;
}

const generateBlogPostTweet = async (
  id: string,
  article: IBusinessArticle,
  onSuccess: (response: string) => void,
  onError: (response: IResponseError) => void
) => {
  try {
    const response: IBusinessTweet = (
      await axios.post(
        `${
          process.env.REACT_APP_API_URL as string
        }/businesses/${id}/business_tweet`,
        {
          businessArticle: article['@id'] ? article['@id'] : '',
        }
      )
    ).data;

    onSuccess(response.content);
  } catch (error) {
    onError({ error: 'Unable to generate the tweet!' });
  }
};

export default generateBlogPostTweet;
