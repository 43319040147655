import { FieldValues, UseFormRegisterReturn } from 'react-hook-form';

interface RoleListProps {
  register: UseFormRegisterReturn;
}
const RoleList = ({ register }: RoleListProps) => {
  return (
    <>
      <label
        htmlFor="permission"
        className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white"
      >
        Permission
      </label>
      <div className="w-full rounded-xl border border-gray-200 py-3 pl-2.5 pr-3 text-sm font-normal text-[#636e91] outline-none dark:!border-white/10 dark:!bg-navy-800 dark:text-white sm:w-full md:w-full">
        <select
          className="text-lightFourth w-full rounded-xl bg-white/0 pr-4 text-sm outline-none dark:!bg-navy-800 dark:text-white"
          name=""
          id="permission"
          {...register}
        >
          <option value="ROLE_OWNER">Admin</option>
          <option value="ROLE_QUEUE">Editor (Queue only)</option>
        </select>
      </div>
    </>
  );
};

export default RoleList;
