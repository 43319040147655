import { PageCard } from 'components/card/PageCard';
import { ExplainerText } from '../base/ExplainerText';
import ThemeSwitcher from '../contenttheme/Switcher';
import ListArticleTitles from 'apiplatform/components/businessarticletitle/List';
import Table from './Table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VideoTranscoder from 'views/main/dashboards/default/components/VideoTranscoder';
import Suggestions from './suggestion/Suggestions';
import CreateNewOptions from './CreateNewOptions';

export interface Subpage {
  type:
    | 'none'
    | 'business-articles'
    | 'transcript-articles'
    | 'video-transcript'
    | 'video-suggestions';
  transcriptId?: string;
  title?: string;
}

const List = () => {
  const [subpage, setSubpage] = useState<Subpage>({ type: 'none' });

  const { ctid } = useParams();

  useEffect(() => {
    setSubpage({ type: 'none' });
  }, [ctid]);

  return (
    <>
      <ExplainerText text="Select from your existing transcripts or upload new media to generate compelling content." />
      <ThemeSwitcher>
        <div className="mb-5">
          {subpage.type === 'business-articles' && (
            <ListArticleTitles setSubpage={setSubpage} subpage={subpage} />
          )}
          {subpage.type === 'transcript-articles' && (
            <ListArticleTitles setSubpage={setSubpage} subpage={subpage} />
          )}
          {subpage.type === 'video-transcript' && (
            <VideoTranscoder setSubpage={setSubpage} />
          )}
          {subpage.type === 'video-suggestions' && (
            <Suggestions setSubpage={setSubpage} subpage={subpage} />
          )}
        </div>
        <PageCard>
          <Table subpage={subpage} setSubpage={setSubpage} />
        </PageCard>
        <CreateNewOptions setSubpage={setSubpage} />
      </ThemeSwitcher>
    </>
  );
};

export default List;
