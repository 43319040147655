import { useEffect, useRef, useState } from 'react';
import Loader from '../../base/Loader';
import { TimeFrame } from './SuggestionVideo';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import SaveMedia from './SaveMedia';

interface VideoTrimmerProps {
  timeFrame: TimeFrame;
  file: File;
  title: string;
}

const VideoTrimmer = ({ timeFrame, file, title }: VideoTrimmerProps) => {
  const [fileReady, setFileReady] = useState<Blob>(null);
  const outFileName = `${timeFrame.startSeconds}-${timeFrame.endSeconds}-${file.name}`;
  const ffmpegRef = useRef(new FFmpeg());

  useEffect(() => {
    const trimFile = async () => {
      const ffmpeg = ffmpegRef.current;

      await ffmpeg.writeFile(
        file.name,
        new Uint8Array(await file.arrayBuffer())
      );

      await ffmpeg.exec([
        '-i',
        file.name,
        '-ss',
        `${timeFrame.startSeconds}`,
        '-to',
        `${timeFrame.endSeconds}`,
        '-c:v',
        'copy',
        '-c:a',
        'copy',
        outFileName,
      ]);

      const data = new Blob([await ffmpegRef.current.readFile(outFileName)]);
      setFileReady(() => data);
    };

    const loadFfmpeg = async () => {
      const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
      const ffmpeg = ffmpegRef.current;

      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          'text/javascript'
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          'application/wasm'
        ),
      });

      trimFile();
    };

    loadFfmpeg();
  }, [file, outFileName, timeFrame]);

  return fileReady ? (
    <SaveMedia fileData={fileReady} fileName={outFileName} title={title} />
  ) : (
    <div className="pt-8 text-center">
      <Loader title="We are generating your media file, please wait..." />
    </div>
  );
};

export default VideoTrimmer;
