import { ReactNode, useState } from 'react';
import FullCalendar, { EventInput } from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { PagedCollection } from '../../apiplatform/interfaces/Collection';
import { BusinessArticle } from '../../apiplatform/interfaces/BusinessArticle';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useRetrieve from '../../hooks/useRetrieve';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import TimelineItem from 'components/dataDisplay/TimelineItem';
import { generateTimeLineDate } from 'views/admin/main/applications/calendar/components/TimeLine';

interface EventCalendarProps {
  initialDate: Date;
  onDateClick?: (date: string) => void;
}

function EventCalendar({ initialDate, onDateClick }: EventCalendarProps) {
  const [startDate, setStartDate] = useState<Date>(initialDate);
  const [endDate, setEndDate] = useState<Date>(initialDate);

  const { id } = useParams();

  const { retrieved } = useRetrieve<PagedCollection<BusinessArticle>>(
    startDate === initialDate
      ? ''
      : `/businesses/${id}/scheduled_articles?scheduledAt[after]=${moment(
          startDate
        ).format('YYYY-MM-DD')}&scheduledAt[before]=${moment(endDate).format(
          'YYYY-MM-DD'
        )}`
  );

  const calendarArticles: Record<string, Array<ReactNode>> = {};
  if (retrieved && retrieved['hydra:member']) {
    retrieved['hydra:member'].forEach((article, key) => {
      const date = moment(article.scheduledAt).format('YYYY-MM-DD');
      if (!calendarArticles[date]) {
        calendarArticles[date] = [];
      }

      const { hours, weekday, day } = generateTimeLineDate(article.scheduledAt);

      calendarArticles[date].push(
        <div className="max-w-[450px]" key={key}>
          <TimelineItem
            day={day}
            hours={hours}
            weekday={weekday}
            title={article.title}
            extra="gap-2"
          />
        </div>
      );
    });
  }

  const eventsList: Array<EventInput> = [];
  for (const date in calendarArticles) {
    eventsList.push({
      title: `${calendarArticles[date].length}`,
      start: moment(date).toDate(),
    });
  }

  return (
    <div className="h-[600px] w-full">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        initialView="dayGridMonth"
        contentHeight="600px"
        events={eventsList}
        eventContent={(element) => {
          return (
            <TooltipHorizon
              content={
                <div className="py-2">
                  {
                    calendarArticles[
                      element.event.start.toLocaleDateString('en-CA')
                    ]
                  }
                </div>
              }
            >
              <IconButton extra="!ml-0">
                <span className="block h-[13px] w-[13px] text-center font-bold leading-[13px]">
                  {element.event.title}
                </span>
              </IconButton>
            </TooltipHorizon>
          );
        }}
        initialDate={initialDate}
        editable={true}
        height="100%"
        datesSet={(event) => {
          setStartDate(event.start);
          setEndDate(event.end);
        }}
        dateClick={(info) => {
          onDateClick(info.dateStr);
        }}
        eventClick={(info) => {
          onDateClick(moment(info.event.start).format('YYYY-MM-DD'));
        }}
      />
    </div>
  );
}

export default EventCalendar;
