import Button from '../../../components/fields/Button';
import CopyToClipboard from '../../../Modal/CopyToClipboard';
import WordpressModal from '../../../Modal/WordPress/WordpressModal';
import TwitterModal from '../../../Modal/Twitter/TwitterModal';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Calendar from '../../../views/admin/main/applications/calendar';
import TooltipHorizon from 'components/tooltip';
import Delete from '../businessarticletitle/Delete';
import { MediaObject } from 'apiplatform/interfaces/MediaObject';
import { Link } from 'react-router-dom';
import TResource from './type';
import { PagedCollection } from '../../interfaces/Collection';
import CenteredButtons from '../base/CenteredButtons';
import ContentStatus from '../../../Modal/ContentStatus';
import { MutableRefObject, useState } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';

interface ArticleButtonsProps {
  article: TResource;
  wpRetrieved: PagedCollection<TResource> | null;
  editorRef: MutableRefObject<TinyMCEEditor>;
  wpLoading: boolean;
  updated: boolean;
  update: (article: TResource, values: any) => void;
  loading: boolean;
  content: string;
  title: string;
  onClose: () => void;
}

const ArticleButtons = ({
  article,
  editorRef,
  wpRetrieved,
  wpLoading,
  updated,
  update,
  loading,
  content,
  title,
  onClose,
}: ArticleButtonsProps) => {
  const [ArticleStatus, setArticleStatus] = useState(article.status);

  return (
    <CenteredButtons leftAlign={article.type !== 'article'}>
      {article.type === 'article' && (
        <>
          {/* Save Button */}
          <Button
            type="blue"
            label="Save"
            customButton={`rounded-lg ${loading ? 'px-6' : 'px-4'} py-1`}
            customSpinner="absolute left-0.5 top-2 -mt-0.5"
            loading={loading}
            completed={updated && !loading}
            onClick={() => {
              update(article, { content: content, title: title });
            }}
          />
          {/* Calendar Button */}
          <EmptyModalBox
            width={1000}
            item={
              <TooltipHorizon
                content={
                  wpRetrieved && wpRetrieved['@id'] === '' ? (
                    <>
                      Please Setup <b>CMS Integration</b> first
                    </>
                  ) : null
                }
              >
                <Button
                  type="blue"
                  label="Schedule"
                  customButton="py-1 rounded-lg px-4"
                  leftMargin
                  disabled={wpRetrieved && wpRetrieved['@id'] === ''}
                  className={`${ArticleStatus === 'sent' ? 'hidden' : ''}`}
                />
              </TooltipHorizon>
            }
          >
            <Calendar article={article} />
          </EmptyModalBox>

          {/* WordPress Button */}
          {(ArticleStatus === 'sent' && article.url != null && (
            <Button
              label="View on Website"
              type="blue"
              customButton="rounded-lg px-4 py-1 py-1"
              leftMargin
              onClick={() => window.open(article.url, '_blank')}
            />
          )) ||
            (article.status === 'sent' || wpLoading ? null : (
              <EmptyModalBox
                item={
                  <TooltipHorizon
                    content={
                      wpRetrieved && wpRetrieved['@id'] === '' ? (
                        <>
                          Please Setup <b>CMS Integration</b> first
                        </>
                      ) : null
                    }
                  >
                    <Button
                      label="Publish Now"
                      customButton="rounded-lg px-4 py-1 py-1"
                      className={`${ArticleStatus === 'sent' ? 'hidden' : ''} `}
                      type="blue"
                      disabled={wpRetrieved && wpRetrieved['@id'] === ''}
                      leftMargin
                    />
                  </TooltipHorizon>
                }
              >
                <WordpressModal
                  schedule={false}
                  article={article}
                  wordpress={wpRetrieved}
                  articleScheduled={article}
                />
              </EmptyModalBox>
            ))}

          {/* Twitter Button */}
          <EmptyModalBox
            item={
              <Button
                type="blue"
                label="Social Media Share"
                leftMargin
                customButton="rounded-lg px-4 py-1 py-1"
              />
            }
          >
            <TwitterModal article={article} articleScheculed={article} />
          </EmptyModalBox>

          {/* Copy Button */}
          <EmptyModalBox item={<CopyToClipboard editorRef={editorRef} title={article.title}/>}>
            <ContentStatus
              setArticleStatus={(status) => {
                update(article, { status });
                setArticleStatus(status);
              }}
            />
          </EmptyModalBox>
        </>
      )}

      {(article.type === 'audio' || article.type === 'video') && (
        <>
          <Button
            type="blue"
            label="Save"
            customButton={`rounded-lg ${loading ? 'px-6' : 'px-4'} py-1`}
            customSpinner="absolute left-0.5 top-2 -mt-0.5"
            loading={loading}
            completed={updated && !loading}
            onClick={() => {
              update(article, { title: title });
            }}
          />
          <EmptyModalBox
            item={
              <Link
                to={(article.mediaObject as MediaObject).contentUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  type="blue"
                  customButton="rounded-lg px-4 py-1"
                  leftMargin
                  label="Download"
                />
              </Link>
            }
          >
            <ContentStatus
              setArticleStatus={(status) => {
                update(article, { status });
                setArticleStatus(status);
              }}
            />
          </EmptyModalBox>
        </>
      )}

      {/* Delete Button */}
      <EmptyModalBox
        item={
          <Button
            type="red"
            label={
              article.type === 'audio' || article.type === 'video'
                ? 'Delete Clip'
                : 'Delete Article'
            }
            leftMargin
            customButton="rounded-lg px-4 py-1"
          />
        }
      >
        <Delete article={article} close={onClose} />
      </EmptyModalBox>
    </CenteredButtons>
  );
};

export default ArticleButtons;
