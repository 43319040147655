import SmallSpinner from 'components/spinner/SmallSpinner';
import { MouseEventHandler, ReactElement, useState, useEffect } from 'react';

interface ButtonProps {
  type: 'brand' | 'gray' | 'navy' | 'green' | 'red' | 'blue' | 'white';
  label: ReactElement | string;
  loading?: boolean;
  completed?: boolean;
  customButton?: string;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonType?: 'button' | 'submit' | 'reset';
  leftMargin?: boolean | 'modal';
  customSpinner?: string;
}

const Button = ({
  type,
  label,
  loading,
  completed,
  disabled,
  className,
  customButton,
  customSpinner,
  leftMargin,
  onClick,
  buttonType,
}: ButtonProps) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [originalType, setOriginalType] = useState(type);

  useEffect(() => {
    if (!loading && completed) {
      setOriginalType(type);
      setIsCompleted(true);
      const timer = setTimeout(() => {
        setIsCompleted(false); 
      }, 1000);
      return () => clearTimeout(timer); 
    }
  }, [loading, completed, type]);

  let bgColorClass, textColorClass, borderClass;
  const buttonTypeToUse = isCompleted ? 'green' : originalType;

  if (buttonTypeToUse === 'blue') {
    bgColorClass =
      'bg-blue-900 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200';
    textColorClass = 'text-white';
  } else if (buttonTypeToUse === 'white') {
    bgColorClass = 'bg-white';
    textColorClass = 'text-gray-600 hover:text-gray-800';
    borderClass = 'border border-gray-300 hover:border-gray-700';
  } else if (buttonTypeToUse === 'green') {
    bgColorClass =
      'bg-green-600 hover:bg-green-500 active:bg-[#296f1b] dark:bg-green-600 dark:hover:bg-green-500 dark:active:bg-[#2b7d22]';
    textColorClass = 'text-white';
  } else {
    bgColorClass = `bg-${buttonTypeToUse}-500 hover:bg-${buttonTypeToUse}-600 active:bg-${buttonTypeToUse}-700 dark:bg-${buttonTypeToUse}-400 dark:hover:bg-${buttonTypeToUse}-300 dark:active:bg-${buttonTypeToUse}-200`;
    textColorClass = 'text-white';
  }

  const labelIsShort = typeof label === 'string' && label.length < 6;
  const calculatedPadding = customButton
    ? customButton
    : `rounded-xl  px-5 py-3 ${
        labelIsShort ? 'w-20' : `'rounded-xl pl-${loading ? 8 : 4}`
      }`;

  let classes = [
    bgColorClass,
    borderClass || '',
    textColorClass,
    calculatedPadding,
    ` ${leftMargin === 'modal' ? 'ml-6' : leftMargin ? 'ml-4' : ''}`,
    'text-base',
    'font-medium',
    'transition',
    'relative',
    'duration-200',
    disabled ? 'opacity-50 cursor-not-allowed' : '',
  ];

  return (
    <button
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      className={classes.join(' ') + ' ' + (className ? className : '')}
    >
      {loading ? (
        <SmallSpinner
          className={customSpinner || 'absolute left-2 top-4 -mt-0.5'}
        />
      ) : null}
      {label}
    </button>
  );
};

export default Button;
