import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';
import { SubmissionError, FormProps } from '../../utils/types';
import Submit from '../base/form/Submit';
import { BusinessPrompt } from 'apiplatform/interfaces/BusinessPrompt';
import Header from '../base/Header';
import { titleMapping } from '../../utils/tittleMapping';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<BusinessPrompt>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessPrompt>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof BusinessPrompt]) {
          return;
        }
        setError(errorPath as keyof BusinessPrompt, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<BusinessPrompt> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          register={register}
          name="business"
          placeholder=""
          type="hidden"
          errors={errors}
        />

        <Field
          register={register}
          name="type"
          placeholder=""
          type="hidden"
          errors={errors}
        />

        <div className="mb-8">
          <Header
            text={
              titleMapping[initialValues?.type] ?? initialValues?.type ?? ''
            }
          />

          <Field
            register={register}
            name="content"
            placeholder=""
            type="textarea"
            required
            additionalClassName="h-96"
            errors={errors}
          />

          <Submit label={label} loading={loading} />
          {footer}
        </div>
      </form>
    </>
  );
};

export default Form;
