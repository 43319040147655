import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

interface FieldProps<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
  placeholder: string;
  exampleText?: string;
  type: string;
  step?: string;
  required?: boolean;
  additionalClassName?: string;
  errors: Partial<DeepMap<TFieldValues, FieldError>>;
}

const Field = <TFieldValues extends FieldValues>({
  register,
  name,
  placeholder,
  exampleText, 
  type,
  step,
  required = false,
  additionalClassName = undefined,
  errors,
}: FieldProps<TFieldValues>) => {
  const inputProps: { className: string; placeholder?: string; 'aria-invalid'?: boolean } = {
    className:
      type === 'textarea'
        ? [
            'textarea',
            'w-full',
            'h-32',
            'resize-none',
            'bg-base-200',
            'text-base-50',
            'text-sm',
            'textarea-bordered',
            'outline-none',
            'rounded-xl',
            'border',
            'p-3',
            'bg-white/0',
          ].join(' ') + (additionalClassName ? ' ' + additionalClassName : '')
        : [
            'mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white',
          ].join(' ') + (additionalClassName ? ' ' + additionalClassName : ''),
          placeholder: exampleText,
  };

  if (errors[name]) {
    inputProps.className += ' is-invalid';
    inputProps['aria-invalid'] = true;
  }

  if (!errors[name]) {
    inputProps.className += ' is-valid';
  }

  const params: RegisterOptions<TFieldValues, Path<TFieldValues>> = {};
  if (required) {
    params.required = 'Field is mandatory!';
  }
  if (type === 'number') {
    params.valueAsNumber = true;
  }

  return (
    <div className="mb-4">
      <label>
        <span className="cursor-pointer text-sm font-normal capitalize text-navy-700 dark:text-white">
          {placeholder}
        </span>
        {type === 'textarea' ? (
          <textarea
            id={name}
            {...inputProps}
            {...register(name, params)}
          ></textarea>
        ) : (
          <input
            id={name}
            type={type}
            step={step}
            {...inputProps}
            {...register(name, params)}
          />
        )}
      </label>
      {errors[name] && (
        <div className="mb-2 mt-2 cursor-pointer text-right text-sm font-normal capitalize text-red-700">
          {errors[name]?.message}
        </div>
      )}
    </div>
  );
};

export default Field;
