import Form from './Form';
import ErrorAlert from 'components/alert/ErrorAlert';
import useUpdate from 'hooks/useUpdate';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';
import { useEffect } from 'react';

interface UpdateProps {
  close?: () => void;
  retrieved: BusinessTranscript;
}

const Update = ({ close, retrieved }: UpdateProps) => {
  const {
    updated,
    update,
    loading: updateLoading,
    error: updateError,
  } = useUpdate<BusinessTranscript>();

  useEffect(() => {
    if (updated) {
      close();
    }
  }, [updated, close]);

  return (
    <>
      <Header text="Update Transcript" />

      {updateError && <ErrorAlert message={updateError.message} />}

      <Form
        onSubmit={(values) => {
          update(retrieved, values);
        }}
        error={updateError}
        label="Update"
        loading={updateLoading}
        initialValues={retrieved}
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
      />
    </>
  );
};

export default Update;
