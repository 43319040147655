import axios from 'axios';

interface jwtInterceptorProps {
  user: any;
  signOut: () => void;
}

export function jwtInterceptor({ signOut }: jwtInterceptorProps) {
  axios.interceptors.request.use(
    (config) => {
      const storage = localStorage.getItem('user');

      if (storage !== 'null') {
        const user = JSON.parse(storage);

        config.headers['Authorization'] = `Bearer ${user.token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        signOut();
      }
      return Promise.reject(error);
    }
  );
}
