import { Link } from 'react-router-dom';
import { Business } from 'apiplatform/interfaces/Business';
import CurrentPageBreadcrumb from './CurrentPageBreadCrumb';
import useRetrieve from 'hooks/useRetrieve';
import TooltipHorizon from 'components/tooltip';

interface BusinessProps {
  id: string;
  ctid: string;
}

const BusinessBreadcrumb = ({ id, ctid }: BusinessProps) => {
  const { retrieved } = useRetrieve<Business>(`/businesses/${id}`);

  return (
    <div className="text-sm">
      <TooltipHorizon content="Change Business">
        <Link to="/businesses/manage" className="capitalize">
          {retrieved ? retrieved.name : 'Business'}
        </Link>
      </TooltipHorizon>
      <CurrentPageBreadcrumb />
    </div>
  );
};

export default BusinessBreadcrumb;
