import { Dispatch, SetStateAction } from 'react';
import { CalendarEventDateTime } from '..';

interface TimePickerProps {
  eventDateTime: CalendarEventDateTime;
  setEventDateTime: Dispatch<SetStateAction<CalendarEventDateTime>>;
}

const TimePicker = ({ eventDateTime, setEventDateTime }: TimePickerProps) => {
  return (
    <div className="col-start-1 row-start-2 mb-8 mt-8 text-3xl font-bold text-blue-900 ">
      <div className={`flex w-60`}>
        <div>
          <select
            value={eventDateTime.hour}
            onChange={(val) => {
              setEventDateTime({ ...eventDateTime, hour: val.target.value });
            }}
            className="bg-white"
          >
            {[...Array(12).keys()].map((h) => (
              <option key={h + 1} value={h + 1} className="text-sm">
                {h + 1}
              </option>
            ))}
          </select>
          &nbsp; |&nbsp;
          <select
            value={eventDateTime.minute.toString().padStart(2, '0')}
            onChange={(val) => {
              setEventDateTime({ ...eventDateTime, minute: val.target.value });
            }}
            className="bg-white"
          >
            {[...Array(60).keys()].map((m) => (
              <option
                key={m}
                value={m.toString().padStart(2, '0')}
                className="text-sm"
              >
                {m.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          &nbsp; |&nbsp;
          <select
            value={eventDateTime.period}
            onChange={(val) => {
              setEventDateTime({ ...eventDateTime, period: val.target.value });
            }}
            className="bg-white"
          >
            <option value="AM" className="text-sm">
              AM
            </option>
            <option value="PM" className="text-sm">
              PM
            </option>
          </select>
        </div>
      </div>

      <div className={`mt-1 flex items-center text-xs text-gray-500`}>
        <span className="ml-2 ">Hour</span>
        <span className="ml-[52px] mr-11">Minutes</span>
        <span>AM/PM</span>
      </div>
    </div>
  );
};

export default TimePicker;
