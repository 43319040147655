import { BusinessArticle } from 'apiplatform/interfaces/BusinessArticle';
import Button from 'components/fields/Button';
import useCreate from 'hooks/useCreate';
import { useEffect, useState } from 'react';
import { BusinessArticleImage, Image } from './type';
import CenteredButtons from '../base/CenteredButtons';

interface GenerateImageProps {
  article: BusinessArticle;
  onGenerated?: (image: Image | null) => void;
  setLoading?: (loading: boolean) => void;
  disabled?: boolean;
}

const GenerateImage = ({
  article,
  onGenerated,
  setLoading,
  disabled,
}: GenerateImageProps) => {
  const [prompt, setPrompt] = useState<string>('');
  const [image1Created, setImage1Created] = useState<Image | null>(null);
  const [image2Created, setImage2Created] = useState<Image | null>(null);
  const [image3Created, setImage3Created] = useState<Image | null>(null);

  const generateImage = () => {
    const payload = {
      businessArticle: `/businesses/business_articles/${article.id}`,
      prompt: prompt,
    };
    image1.create(payload);
    image2.create(payload);
    image3.create(payload);
  };

  const image1 = useCreate<BusinessArticleImage, Image>(
    `/businesses/business_articles/image`
  );

  const image2 = useCreate<BusinessArticleImage, Image>(
    `/businesses/business_articles/image`
  );

  const image3 = useCreate<BusinessArticleImage, Image>(
    `/businesses/business_articles/image`
  );

  useEffect(() => {
    if (image1.created) {
      setImage1Created(image1.created);
    }
  }, [image1.created, setImage1Created]);

  useEffect(() => {
    if (image2.created) {
      setImage2Created(image2.created);
    }
  }, [image2.created, setImage2Created]);

  useEffect(() => {
    if (image3.created) {
      setImage3Created(image3.created);
    }
  }, [image3.created, setImage3Created]);

  useEffect(() => {
    if (image1Created !== null) {
      onGenerated(image1Created);
      setImage1Created(null);
    }
  }, [image1Created, onGenerated]);

  useEffect(() => {
    if (image2Created !== null) {
      onGenerated(image2Created);
      setImage2Created(null);
    }
  }, [image2Created, onGenerated]);

  useEffect(() => {
    if (image3Created !== null) {
      onGenerated(image3Created);
      setImage3Created(null);
    }
  }, [image3Created, onGenerated]);

  useEffect(() => {
    if (setLoading) {
      setLoading(image1.loading || image2.loading || image3.loading);
    }
  }, [image1.loading, image2.loading, image3.loading, setLoading]);

  return (
    <>
      <div className="mt-5">
      <div className="pt-4" />
        <div>
          <div className="pb-[2rem]">
            <label className="cursor-pointer text-lg font-semibold text-blue-900 dark:text-white ">
            Generate AI Image - Enter a description below:
            </label>
          </div>
          <textarea
            placeholder="Create an image based on the Article Title"
            className="!inline-block flex  h-[100px] w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0
             p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
            onChange={(e) => setPrompt(e.target.value)}
            disabled={image1.loading || image2.loading || image3.loading}
          ></textarea>
        </div>

        <CenteredButtons modal>
          <Button
            className="ml-6"
            type="blue"
            label="Generate AI Image"
            onClick={generateImage}
            buttonType="button"
            loading={image1.loading || image2.loading || image3.loading}
            disabled={
              image1.loading || image2.loading || image3.loading || disabled
            }
          />
        </CenteredButtons>
      </div>
    </>
  );
};

export default GenerateImage;
