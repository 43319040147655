import { FunctionComponent } from 'react';
import Button from '../../components/fields/Button';
import CenteredButtons from '../../apiplatform/components/base/CenteredButtons';
import sendToWordpress from '../../Request/sendToWordpress';
import scheduleAtricleSending from '../../Request/scheduleArticleSending';

interface WordpressButtonsProps {
  articleId: string;
  article: any;
  articleScheduled: any;
  wordpress: any;
  schedule: boolean;
  sendingToWordpress: boolean;
  sendingToWordpressStatus: boolean | null;
  setSendingToWordpress: (status: boolean) => void;
  setSendingToWordpressStatus: (status: boolean | null) => void;
  setError: (error: string) => void;
  error: string;
  onClose?: () => void;
}

const WordpressButtons: FunctionComponent<WordpressButtonsProps> = ({
  articleId,
  article,
  articleScheduled,
  wordpress,
  schedule,
  sendingToWordpress,
  sendingToWordpressStatus,
  setSendingToWordpress,
  setSendingToWordpressStatus,
  setError,
  error,
  onClose,
}) => {
  const handleSend = () => {
    if (wordpress !== null && articleId !== undefined) {
      articleScheduled.url = wordpress.url;
      articleScheduled.publishedAt = new Date().toISOString();
      setSendingToWordpress(true);
      schedule
        ? scheduleAtricleSending(
            articleId,
            article,
            () => {
              setSendingToWordpress(false);
              setSendingToWordpressStatus(true);
            },
            (err) => {
              setError(err.error);
              setSendingToWordpress(false);
              setSendingToWordpressStatus(false);
            }
          )
        : sendToWordpress(
            articleId,
            article,
            () => {
              setSendingToWordpress(false);
              setSendingToWordpressStatus(true);
            },
            (err) => {
              setError(err.error);
              setSendingToWordpress(false);
              setSendingToWordpressStatus(false);
            }
          );
    }
    if (sendingToWordpressStatus === true) {
      onClose && onClose();
    }
  };

  return (
    <CenteredButtons modal>
      <Button
        type="blue"
        loading={sendingToWordpress}
        completed={sendingToWordpressStatus}
        label="Send"
        onClick={handleSend}
      />
      <Button type="blue" label="Cancel" onClick={onClose} leftMargin />
    </CenteredButtons>
  );
};

export default WordpressButtons;
