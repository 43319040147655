import { PageCard } from 'components/card/PageCard';
import { MutableRefObject } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
interface InsertImageProps {
  close?: () => void;
  editorRef: MutableRefObject<TinyMCEEditor>;
}

const ArticleHistory = ({ close, editorRef }: InsertImageProps) => {
  return (
    <PageCard>
      <div className="h-[400px] w-[600px]">
        <h1 className="mb-8 text-3xl font-bold">Revision History</h1>
      </div>
    </PageCard>
  );
};

export default ArticleHistory;
