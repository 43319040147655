import { useParams } from 'react-router-dom';
import Form from './Form';
import { PageCard } from 'components/card/PageCard';
import ErrorAlert from 'components/alert/ErrorAlert';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import useCreate from 'hooks/useCreate';
import Header from '../base/Header';
import Button from 'components/fields/Button';
import { useEffect } from 'react';

interface CreateProps {
  close?: () => void;
}

const Create = ({ close }: CreateProps) => {
  const { created, loading, error, create } = useCreate<ContentTheme>(
    '/businesses/content_themes'
  );
  const { id: businessId } = useParams();

  useEffect(() => {
    if (created) {
      close();
    }
  }, [created, close]);

  return (
    <PageCard>
      <Header text="Add Content Theme" />
      {error && <ErrorAlert message={error.message} />}
      <Form
        onSubmit={create}
        error={error}
        loading={loading}
        label="Create"
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
        initialValues={{
          business: `/businesses/${businessId}`,
        }}
      />
    </PageCard>
  );
};

export default Create;
