import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { FcGoogle } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NoAuthCentered from 'layouts/auth/types/NoAuthCentered';
import TooltipHorizon from 'components/tooltip';

function SignUp() {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const handleEmail = (event: any) => setEmail(event.target.value);
  const handlePassword = (event: any) => setPassword(event.target.value);

  const navigate = useNavigate();

  useEffect(() => {
    setEmailError('');
    setPasswordError('');
  }, [email, password]);

  const CreateAccount = async (email: string, password: string) => {
    const url = process.env.REACT_APP_API_URL + '/users/signup';
    await axios
      .post(url, { email: email, password: password })
      .then((response) => navigate('/signup/success'))
      .catch((r) => {
        const errors = r.response.data;

        if (errors.violations) {
          for (const error of errors.violations) {
            if (error.propertyPath === 'email') {
              setEmailError(error.message);
            }
            if (error.propertyPath === 'password') {
              setPasswordError(error.message);
            }
          }
        }
      });
  };

  const handleCreate = async () => {
    CreateAccount(email, password);
  };

  return (
    <NoAuthCentered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] h-max mx-2.5 md:mx-auto mt-12 mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
            Sign Up
          </h3>
          <p className="ml-1 mt-[10px] text-base text-[#636e91]">
            Enter your email and password to sign up!
          </p>
          <TooltipHorizon content="Functionality is not ready yet">
            <div className="mt-9 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary opacity-[0.5] dark:!bg-navy-700">
              <div className="rounded-full text-xl">
                <FcGoogle />
              </div>
              <p className="text-sm font-medium text-navy-700 dark:text-white">
                Sign Up with Google
              </p>
            </div>
          </TooltipHorizon>
          <div className="mb-4 mt-6 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            <p className="text-base font-medium text-[#636e91]"> or </p>
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
          </div>
          {/* user info */}
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="email"
            onChange={handleEmail}
            state={emailError !== '' ? 'error' : ''}
          />
          <small className="text-red-500">{emailError}</small>
          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min 8 characters"
            id="password"
            type="password"
            onChange={handlePassword}
            state={passwordError !== '' ? 'error' : ''}
          />
          <small className="text-red-500">{passwordError}</small>

          <button
            className="linear  mt-4 w-full rounded-xl bg-blue-900 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={handleCreate}
          >
            Create my account
          </button>

          <div className="mt-3">
            <span className="text-sm font-medium text-blue-900 dark:text-gray-500">
              Already a member?
            </span>
            <Link
              className="ml-1 text-sm font-medium text-blue-900 hover:text-blue-900 dark:text-white"
              to="/"
            >
              Sign In
            </Link>
          </div>
        </Card>
      }
    />
  );
}

export default SignUp;
