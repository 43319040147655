import axios from 'axios';
import IBusinessArticle from '../Database/Model/BusinessArticles';
import IResponseError from '../Type/IResponseError';

const sendToWordpress = async (
  id: string,
  article: IBusinessArticle,
  onSuccess: () => void,
  onError: (response: IResponseError) => void
) => {
  try {
    const axiosResponse = await axios.post(
      `${
        process.env.REACT_APP_API_URL as string
      }/businesses/${id}/send_article_to_wordpress` as string,
      {
        businessArticle: article['@id'],
      }
    );

    if (axiosResponse.status < 200 || axiosResponse.status >= 300) {
      throw new Error();
    }

    onSuccess();
  } catch (error) {
    onError({ error: 'Unable to send the article to wordpress!' });
  }
};

export default sendToWordpress;
