import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface SelectedVideoContextData {
  transcriptId?: string;
  file?: File;
}

const SelectedVideoContext = createContext<
  [SelectedVideoContextData, Dispatch<SetStateAction<SelectedVideoContextData>>]
>([{}, () => {}]);

export const SelectedVideoProvider = ({ children }: PropsWithChildren) => {
  const [selectedVideo, setSelectedVideo] = useState<SelectedVideoContextData>(
    {}
  );

  return (
    <SelectedVideoContext.Provider value={[selectedVideo, setSelectedVideo]}>
      {children}
    </SelectedVideoContext.Provider>
  );
};

export const useSelectedVideo = () => useContext(SelectedVideoContext);
