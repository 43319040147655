export const titleMapping: Record<string, string> = {
    ArticleTitlesFromBusinessDetails: 'Article Titles From Business Details',
    ArticleFromBusinessDetails: 'Article From Business Details',
    TweetFromBusinessDetails: 'Tweet From Business Details',
    ArticleTitlesFromTranscript: 'Article Titles From Transcript',
    ArticleFromTranscript: 'Article From Transcript',
    ArticleRevision: 'Article Revision',
    TranscriptSummary: 'Transcript Summary',
    ParagraphsFromTranscript: 'Formatting For Transcripts',
    TitleFromTranscript: 'Video Highlights',
    ImageFromArticleTitle: 'Image From Article Title',
  };
  