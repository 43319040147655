import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { AuthProvider } from 'components/auth/AuthProvider';
import AppQueryClient from 'AppQueryClient';

ReactDOM.createRoot(document.getElementById('root')).render(
  <AppQueryClient>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </AppQueryClient>
);
