import Card from 'components/card';
import { PropsWithChildren } from 'react';

interface PageCardProps extends PropsWithChildren {
  innerPaddingOff?: boolean;
  hidden?: boolean;
}

export function PageCard({ innerPaddingOff, hidden, children }: PageCardProps) {
  return (
    <Card
      extra={`w-full relative h-full px-4 pb-8 sm:overflow-x-auto ${
        hidden ? 'hidden' : ''
      }`}
    >
      <div
        className={`mt-8 overflow-x-scroll ${
          innerPaddingOff ? '' : 'px-4'
        } xl:overflow-x-hidden`}
      >
        {children}
      </div>
    </Card>
  );
}
