import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { Business } from 'apiplatform/interfaces/Business';
import { PagedCollection } from 'apiplatform/interfaces/Collection';
import useRetrieve from 'hooks/useRetrieve';
import { UseFormRegisterReturn } from 'react-hook-form';

interface BusinessListProps {
  register: UseFormRegisterReturn;
}

const BusinessList = ({ register }: BusinessListProps) => {
  const { url } = useCollectionUrl('/businesses');
  const { retrieved, loading } = useRetrieve<PagedCollection<Business>>(url);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <label
        htmlFor="business"
        className="mb-2 ml-3 text-sm font-bold text-navy-700 dark:text-white"
      >
        Business
      </label>
      <div className="w-full rounded-xl border border-gray-200 py-3 pl-2.5 pr-3 text-sm font-normal text-[#636e91] outline-none dark:!border-white/10 dark:!bg-navy-800 dark:text-white sm:w-full md:w-full">
        <select
          className="text-lightFourth w-full rounded-xl bg-white/0 pr-4 text-sm outline-none dark:!bg-navy-800 dark:text-white"
          name=""
          id="business"
          {...register}
        >
          {retrieved['hydra:member'].map((business: Business) => (
            <option key={business.id} value={business['@id']}>
              {business.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default BusinessList;
