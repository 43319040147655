import Card from '../../../components/card';

interface CreateNewProps {
  title?: string;
  description: string;
  color: string;
  label: string;
  onClick?: () => void;
  extraPadding?: string;
  className?:string
  children?: React.ReactNode;
}

const CreateNew = ({
  title,
  description,
  color,
  label,
  onClick,
  extraPadding = 'py-8',
  className,
  children,
}: CreateNewProps) => {
  return (
    <div className="relative flex transform transition-transform duration-300 hover:-translate-y-3">
      <div
        className="absolute right-0 top-0 z-10 cursor-default"
      >
        <div
          className={`rounded-full px-3 w-20 text-center py-1 text-sm font-medium text-white bg-${color} `}
        >
          {label}
        </div>
      </div>

      <Card extra={` ${extraPadding || 'py-9'} px-5`}>
        <div className={`${className}`}>
          {children ? (
            <div className="cursor-pointer">{children}</div>
          ) : (
            <div className="cursor-pointer" onClick={onClick}>
              <p className="mt-1 text-[14px] font-medium text-gray-800">
                {title}
              </p>
              <p className="mt-1 text-wrap text-[12px] text-[#636e91]">
                {description}
              </p>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CreateNew;
