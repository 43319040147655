import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResource, SubmissionError } from 'apiplatform/utils/types';
import axios from 'axios';
import { buildQueryKeys, throwSubmissionError } from 'hooks';
import { useState } from 'react';

interface ICreateOptions {
  noInvalidate?: boolean;
}

const useCreate = <
  TypeRequest extends ApiResource,
  TypeResponse extends ApiResource = TypeRequest
>(
  iri: string,
  options?: ICreateOptions
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate,
    error,
    data: created,
  } = useMutation<TypeResponse, SubmissionError, Partial<TypeRequest>>({
    mutationFn: async (resource) => {
      setLoading(() => true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + iri,
          resource,
          {
            headers: {
              'Content-Type': 'application/ld+json',
            },
          }
        );

        return response.data;
      } catch (e) {
        if (e instanceof Error) {
          throwSubmissionError(e);
        }
        throw e;
      }
    },
    onSuccess: () => {
      if (!(options?.noInvalidate === true)) {
        queryClient.invalidateQueries({
          queryKey: [buildQueryKeys(iri)[0]],
        });
      }
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const create = (resource: Partial<TypeRequest>) => {
    mutate(resource);
  };

  return { loading, create, error, created };
};

export default useCreate;
