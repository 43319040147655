import { Manager } from 'apiplatform/interfaces/Manager';
import { PageCard } from 'components/card/PageCard';
import Header from '../base/Header';
import InputField from 'components/fields/InputField';
import { MdDelete } from 'react-icons/md';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Delete from './Delete';

interface EditProps {
  manager: Manager;
  close?: () => void;
}

const Edit = ({ manager, close }: EditProps) => {
  const getPermisionsList = () => {
    return manager.businessPermissions.map((bp, index) => {
      return (
        <div key={index} className="grid grid-cols-3 items-center gap-y-2 mb-2">
          <p className="col-span-1">
            {bp.business.name} :: {bp.roles.join(',')}
          </p>
          <EmptyModalBox
            item={
              <TooltipHorizon content="Delete">
                <IconButton>
                  <MdDelete />
                </IconButton>
              </TooltipHorizon>
            }
          >
            <Delete item={bp} />
          </EmptyModalBox>
        </div>
      );
    });
  };

  return (
    <PageCard innerPaddingOff={true}>
      <div className="px-4">
        <Header text="Edit Manager" />
      </div>

      <InputField
        label="Email"
        placeholder="Email"
        id="email"
        value={manager.email}
        disabled={true}
      />
      <div className="mt-5">{getPermisionsList()}</div>
    </PageCard>
  );
};

export default Edit;
