import React from 'react';
import { PageCard } from '../../../components/card/PageCard';
import { tagData } from '../../utils/tagData';


const TagsTable: React.FC = () => {
  return (
    <PageCard>
      <div className="overflow-x-auto mt-8">
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Tag</th>
              <th className="border px-4 py-2 text-left">Entity/Field</th>
              <th className="border px-4 py-2 text-left">Input</th>
              <th className="border px-4 py-2 text-left">Comment</th>
            </tr>
          </thead>
          <tbody>
            {tagData.map((tag, index) => (
              <tr key={index} className="border-b">
                <td className="border px-4 py-2">{tag.tag}</td>
                <td className="border px-4 py-2">{tag.entityField}</td>
                <td className="border px-4 py-2">{tag.input}</td>
                <td className="border px-4 py-2">{tag.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PageCard>
  );
};

export default TagsTable;
