import { useEffect, useRef } from 'react';
import { State, UI } from './types';

import {
  calculateCurrentTimeBar,
  runWhilePlaying,
  calculateLeftTimeBar,
  calculateRightTimeBar,
  cropLeft,
  cropRight,
  playAction,
  initUI,
  initState,
  stopPropagation,
  stopAction,
  barClickAction,
} from './utils';

export const useUI = (): UI => {
  return {
    videoRef: useRef<HTMLVideoElement>(),
    progressBarRef: useRef<HTMLDivElement>(),
    fullBarRef: useRef<HTMLDivElement>(),

    leftCropRef: useRef<HTMLDivElement>(),
    leftCropBg: useRef<HTMLDivElement>(),

    rightCropRef: useRef<HTMLDivElement>(),
    rightCropBg: useRef<HTMLDivElement>(),

    editorAreaRef: useRef<HTMLDivElement>(),

    add5sLeftRef: useRef<HTMLSpanElement>(),
    add5sRightRef: useRef<HTMLSpanElement>(),
    playRef: useRef<HTMLSpanElement>(),
    stopRef: useRef<HTMLSpanElement>(),

    timeStartRef: useRef<HTMLDivElement>(),
    timeEndRef: useRef<HTMLDivElement>(),
    timeCurrentRef: useRef<HTMLDivElement>(),
  };
};

export const useEditor = (state: State, ui: UI) => {
  useEffect(() => {
    initUI(ui, state.width);

    ui.videoRef.current.onloadedmetadata = () => {
      initState(
        state,
        state.startSeconds > ui.videoRef.current.duration
          ? Math.round(ui.videoRef.current.duration - 1)
          : state.startSeconds,
        !state.endSeconds || state.endSeconds > ui.videoRef.current.duration
          ? Math.round(ui.videoRef.current.duration)
          : state.endSeconds
      );

      ui.videoRef.current.currentTime = state.startSeconds;

      calculateLeftTimeBar(state, ui);
      calculateRightTimeBar(state, ui);
      calculateCurrentTimeBar(state, ui);
    };

    ui.videoRef.current.onplay = () => {
      runWhilePlaying(state, ui);
      state.playingInterval = setInterval(() => {
        runWhilePlaying(state, ui);
      }, 1000);
    };

    ui.videoRef.current.onpause = () => {
      clearInterval(state.playingInterval);
    };

    window.onmousemove = (evt) => {
      if (state.leftCropMouseDown) {
        document.body.style.cursor = 'col-resize';
        cropLeft(state, ui, evt.screenX);
        calculateCurrentTimeBar(state, ui);
        calculateLeftTimeBar(state, ui);
      } else if (state.rightCropMouseDown) {
        document.body.style.cursor = 'col-resize';
        cropRight(state, ui, evt.screenX);
        calculateCurrentTimeBar(state, ui);
        calculateRightTimeBar(state, ui);
      } else {
        document.body.style.cursor = 'auto';
      }
    };

    window.onmouseup = () => {
      state.leftCropMouseDown = false;
      state.rightCropMouseDown = false;
      document.body.style.cursor = 'auto';
    };

    ui.leftCropRef.current.onmousedown = (evt) => {
      stopPropagation(evt);

      if (state.leftCropX === 0) {
        state.leftCropX = evt.screenX;
      }
      state.leftCropMouseDown = true;
    };

    ui.rightCropRef.current.onmousedown = (evt) => {
      stopPropagation(evt);

      if (state.rightCropX === state.width - 1) {
        state.rightCropX = evt.screenX;
      }
      state.rightCropMouseDown = true;
    };

    ui.fullBarRef.current.onclick = (evt) => {
      stopPropagation(evt);
      barClickAction(state, ui, evt.offsetX);
    };

    ui.playRef.current.onclick = (evt) => {
      stopPropagation(evt);
      playAction(state, ui);
    };

    ui.stopRef.current.onclick = (evt) => {
      stopPropagation(evt);
      stopAction(state, ui);
    };
  });
};
