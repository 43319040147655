import { PageCard } from 'components/card/PageCard';
import Header from '../base/Header';
import CenteredButtons from '../base/CenteredButtons';
import Button from 'components/fields/Button';

interface RestoreDialogProps {
  close: () => void;
  loading: boolean;
  click: () => void;
}

const RestoreDialog = ({ close, loading, click }: RestoreDialogProps) => {
  return (
    <PageCard>
      <Header text="Restore Default Prompts" />

      <p>Are you sure you want to restore default prompts?</p>

      <CenteredButtons>
        <Button
          type="red"
          label="Restore"
          loading={loading}
          onClick={() => click()}
        />
        <Button type="blue" label="Cancel" onClick={close} className="ml-6" />
      </CenteredButtons>
    </PageCard>
  );
};

export default RestoreDialog;
