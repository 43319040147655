import restorePrompt from 'Request/restorePrompt';
import RestoreDialog from './RestoreDialog';
import { Dispatch, SetStateAction, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

interface RestorePromptProps {
  id: number;
  close?: () => void;
  setKey?: Dispatch<SetStateAction<number>>;
}

const RestorePrompt = ({ id, close, setKey }: RestorePromptProps) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const restore = async () => {
    setLoading(true);
    await restorePrompt(
      id,
      async () => {
        await queryClient.invalidateQueries({
          queryKey: ['businesses'],
        });

        setLoading(false);
        close();
        setKey(Date.now());
      },
      () => {}
    );
  };

  return <RestoreDialog close={close} loading={loading} click={restore} />;
};

export default RestorePrompt;
