import { State, UI } from './types';

export const convertSecondsToStringTime = (seconds: number) => {
  const iso = new Date(Math.round(Math.max(0, seconds)) * 1000).toISOString();
  if (seconds < 3600) {
    return iso.substring(14, 19);
  }
  if (seconds < 36000) {
    return iso.substring(12, 19);
  }
  return iso.substring(11, 19);
};

export const calculateCurrentTimeBar = (state: State, ui: UI) => {
  const currTimeS = ui.videoRef.current.currentTime - state.minLeftPosition;

  ui.timeCurrentRef.current.innerHTML =
    convertSecondsToStringTime(currTimeS) +
    ' - ' +
    convertSecondsToStringTime(
      state.rightCropEndTime - state.leftCropStartTime
    );
};

export const runWhilePlaying = (state: State, ui: UI) => {
  if (ui.videoRef.current.currentTime >= state.maxRightPosition) {
    ui.playRef.current.click();
  }

  state.progress = Math.min(
    1,
    (ui.videoRef.current.currentTime - state.minLeftPosition) /
      Math.max(1, state.maxRightPosition - state.minLeftPosition)
  );

  ui.progressBarRef.current.style.width =
    Math.round(state.progress * (state.rightCropPos - state.leftCropPos)) +
    'px';

  calculateCurrentTimeBar(state, ui);
};

export const createState = (
  width: number,
  startSeconds: number,
  endSeconds: number
): State => {
  return {
    leftCropMouseDown: false,
    leftCropX: 0,
    leftCropPos: 0,
    leftCropStartTime: 0,
    rightCropMouseDown: false,
    rightCropX: width - 1,
    rightCropPos: width - 1,
    rightCropEndTime: 0,
    duration: 0,
    play: false,
    playingInterval: null,
    minLeftPosition: 0,
    maxRightPosition: 0,
    progress: 0,
    startSeconds: startSeconds,
    endSeconds: endSeconds,
    width: width,
  };
};

export const initUI = (ui: UI, width: number) => {
  ui.progressBarRef.current.style.width = '0px';
  ui.progressBarRef.current.style.left = '0px';
  ui.leftCropBg.current.style.width = '0px';
  ui.rightCropBg.current.style.width = '0px';
  ui.leftCropRef.current.style.left = '0px';
  ui.rightCropRef.current.style.left = width - 1 + 'px';
};

export const initState = (
  state: State,
  startSeconds: number,
  endSeconds: number
) => {
  state.startSeconds = startSeconds;
  state.endSeconds = endSeconds;
  state.duration = endSeconds - startSeconds;
  state.minLeftPosition = startSeconds;
  state.maxRightPosition = endSeconds;
  state.leftCropStartTime = startSeconds;
  state.rightCropEndTime = endSeconds;
};

export const calculateMousePosition = (position: number, width: number) => {
  if (position < 0) {
    return 0;
  }
  if (position >= width) {
    return width - 1;
  }
  return position;
};

export const calculateLeftTimeBar = (state: State, ui: UI) => {
  ui.timeStartRef.current.innerHTML = convertSecondsToStringTime(
    state.leftCropStartTime
  );
};

export const calculateRightTimeBar = (state: State, ui: UI) => {
  ui.timeEndRef.current.innerHTML = convertSecondsToStringTime(
    state.rightCropEndTime
  );
};

export const cropLeft = (state: State, ui: UI, screenX: number) => {
  let pos = calculateMousePosition(screenX - state.leftCropX, state.width);

  if (pos >= state.rightCropPos) {
    pos = state.rightCropPos - 1;
  }

  ui.progressBarRef.current.style.width = '0px';
  ui.progressBarRef.current.style.left = pos + 'px';

  state.leftCropPos = pos;

  ui.leftCropRef.current.style.left = pos + 'px';
  ui.leftCropBg.current.style.width = pos + 'px';

  state.minLeftPosition =
    state.startSeconds +
    Math.round(
      ((state.endSeconds - state.startSeconds) * pos) / (state.width - 1)
    );

  state.leftCropStartTime =
    state.startSeconds + Math.round(state.duration * (pos / (state.width - 1)));
  ui.videoRef.current.currentTime = state.leftCropStartTime;
};

export const cropRight = (state: State, ui: UI, screenX: number) => {
  let pos = calculateMousePosition(
    screenX - state.rightCropX + (state.width - 1),
    state.width
  );

  if (pos <= state.leftCropPos) {
    pos = state.leftCropPos + 1;
  }

  state.maxRightPosition =
    state.startSeconds +
    Math.round(
      ((state.endSeconds - state.startSeconds) * pos) / (state.width - 1)
    );

  state.rightCropPos = pos;

  ui.rightCropRef.current.style.left = pos + 'px';
  ui.rightCropBg.current.style.width = state.width - 1 - pos + 'px';

  state.rightCropEndTime =
    state.startSeconds + Math.round(state.duration * (pos / (state.width - 1)));
};

export const playAction = (state: State, ui: UI) => {
  state.play = !state.play;
  if (state.play) {
    ui.playRef.current.querySelector('.pause').classList.remove('hidden');
    ui.playRef.current.querySelector('.play').classList.add('hidden');
    ui.videoRef.current.play();
  } else {
    ui.playRef.current.querySelector('.pause').classList.add('hidden');
    ui.playRef.current.querySelector('.play').classList.remove('hidden');
    ui.videoRef.current.pause();
  }
};

export const stopAction = (state: State, ui: UI) => {
  if (state.play) {
    ui.playRef.current.click();
  }

  ui.videoRef.current.currentTime = state.leftCropStartTime;
  ui.progressBarRef.current.style.width = '0px';
  ui.progressBarRef.current.style.left = state.leftCropPos + 'px';

  calculateCurrentTimeBar(state, ui);
};

export const barClickAction = (state: State, ui: UI, offsetX: number) => {
  if (offsetX > state.leftCropPos && offsetX < state.rightCropPos) {
    ui.progressBarRef.current.style.width =
      Math.round(offsetX - state.leftCropPos) + 'px';

    ui.videoRef.current.currentTime =
      state.leftCropStartTime +
      Math.round(
        ((state.rightCropEndTime - state.leftCropStartTime) *
          (offsetX - state.leftCropPos)) /
          (state.rightCropPos - state.leftCropPos)
      );

    calculateCurrentTimeBar(state, ui);
  }
};

export const stopPropagation = (evt: MouseEvent) => {
  evt.stopPropagation();
  evt.preventDefault();
};
