import Loader from 'apiplatform/components/base/Loader';
import { BusinessArticle } from 'apiplatform/interfaces/BusinessArticle';
import { MediaObject } from 'apiplatform/interfaces/MediaObject';
import useCreate from 'hooks/useCreate';
import useMedia from 'hooks/useMedia';
import { useEffect, useRef } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

interface SaveMediaProps {
  fileData: Blob;
  fileName: string;
  title: string;
}

const SaveMedia = ({ title, fileData, fileName }: SaveMediaProps) => {
  const saveMediaRan = useRef(false);
  const saveArticleRan = useRef(false);
  const extension = fileName.split('.').pop();

  const { id, ctid } = useParams();

  const { create: createMedia, created: mediaCreated } = useMedia<MediaObject>(
    '/media_objects',
    {
      noInvalidate: true,
    }
  );

  const { create: createArticle, created: articleCreated } =
    useCreate<BusinessArticle>('/businesses/business_articles', {
      noInvalidate: true,
    });

  useEffect(() => {
    if (mediaCreated && !saveArticleRan.current) {
      createArticle({
        title: title,
        type: extension === 'mp3' || extension === 'm4a' ? 'audio' : 'video',
        mediaObject: mediaCreated['@id'],
        content: '',
        source: 'transcript',
        business: `/businesses/${id}`,
        contentTheme: `/businesses/content_themes/${ctid}`,
      });
      saveArticleRan.current = true;
    }
  }, [mediaCreated, id, ctid, createArticle, title, extension]);

  useEffect(() => {
    if (!saveMediaRan.current) {
      createMedia([
        {
          name: 'file',
          blobValue: fileData,
          filename: fileName,
        },
      ]);
    }

    saveMediaRan.current = true;
  }, [createMedia, title, fileData, fileName]);

  return articleCreated ? (
    <div className="pb-8 pt-8 text-navy-700">
      <IoMdCheckmark className="inline" /> Your media file has been saved.{' '}
      <Link
        to={`/business/${id}/theme/${ctid}/queue?title=${title}`}
        className="text-brand-500"
      >
        Click here
      </Link>{' '}
      to see it.
    </div>
  ) : (
    <div className="pt-8 text-center">
      <Loader title="We are saving your media file, please wait..." />
    </div>
  );
};

export default SaveMedia;
