import { FunctionComponent } from 'react';
import { FaRegLightbulb } from 'react-icons/fa6';
import Button from '../../components/fields/Button';
import Loader from '../../apiplatform/components/base/Loader';

interface TweetContentProps {
  tweetLoading: boolean;
  articleUrl: string;
  copied: boolean;
  onCopy: () => void;
  tweet: string;
}

const TweetContent: FunctionComponent<TweetContentProps> = ({
  tweetLoading,
  articleUrl,
  copied,
  onCopy,
  tweet,
}) => {
  return (
    <>
      {tweetLoading ? (
        <>
          <button
            className="rounded-xl bg-gray-200 px-5 py-3 text-base font-medium"
            onClick={() => <Loader />}
          >
            Please wait while your post is being generated
          </button>
          <button
            className="rounded-xl  px-5 py-3 text-base font-medium"
            onClick={() => <Loader />}
          ></button>
        </>
      ) : (
        <>
          <div className="rounded-xl bg-gray-100 px-5 py-3 text-base font-medium">
            {articleUrl ? (
              <div className="flex items-center justify-between pb-2 pt-1">
                <p className="mr-2 truncate">Article URL: {articleUrl}</p>
                <Button
                  className="justify-end"
                  type="blue"
                  label={copied ? 'Copied!' : 'Copy'}
                  onClick={onCopy}
                />
              </div>
            ) : (
              <div className="flex py-3">
                <FaRegLightbulb size={20} className="mr-2 text-yellow-500" />
                <p className="text-sm">
                  Tip: Enhance your article's exposure to more platforms by
                  publishing it!
                </p>
              </div>
            )}
          </div>

          <div
            className="my-3 rounded-xl bg-gray-200 px-5 py-3 text-base font-medium"
            dangerouslySetInnerHTML={{
              __html: tweet
                ? tweet
                    .replaceAll('<p>', '<p class="mb-4">')
                    .replaceAll('</p>', '')
                : '',
            }}
          />
        </>
      )}
    </>
  );
};

export default TweetContent;
