import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Field from '../base/form/Field';

import { SubmissionError, FormProps } from '../../utils/types';
import Submit from '../base/form/Submit';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import CenteredButtons from '../base/CenteredButtons';

const Form = ({
  onSubmit,
  error,
  initialValues,
  label,
  loading,
  footer,
}: FormProps<ContentTheme>) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ContentTheme>({
    defaultValues: initialValues
      ? {
          ...initialValues,
        }
      : undefined,
  });

  useEffect(() => {
    if (error instanceof SubmissionError) {
      Object.keys(error.errors).forEach((errorPath) => {
        if (errors[errorPath as keyof ContentTheme]) {
          return;
        }
        setError(errorPath as keyof ContentTheme, {
          type: 'server',
          message: error.errors[errorPath],
        });
      });
    }
  }, [error, errors, setError]);

  const onFormSubmit: SubmitHandler<ContentTheme> = (data) => {
    onSubmit({
      ...data,
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Field
        register={register}
        name="business"
        placeholder=""
        type="hidden"
        errors={errors}
      />
      <Field
        register={register}
        name="name"
        placeholder="Name"
        type="text"
        errors={errors}
      />
      <Field
        register={register}
        name="keywords"
        placeholder="Keywords"
        type="text"
        errors={errors}
      />
      <Field
        register={register}
        name="description"
        placeholder="Description"
        type="text"
        errors={errors}
      />

      <CenteredButtons modal>
        <Submit label={label} loading={loading} />
        {footer}
      </CenteredButtons>
    </form>
  );
};

export default Form;
