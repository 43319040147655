import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResource } from 'apiplatform/utils/types';
import axios from 'axios';
import { buildQueryKeys, throwSubmissionError } from 'hooks';
import { useState } from 'react';

interface IUpdateParameters<IResource extends ApiResource> {
  resource: IResource;
  values: Partial<IResource>;
}

interface IUpdateOptions {
  noInvalidate?: boolean;
}

const useUpdate = <IResource extends ApiResource>(options?: IUpdateOptions) => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    mutate,
    error,
    data: updated,
  } = useMutation<IResource, Error, IUpdateParameters<IResource>>({
    mutationFn: async (parameters) => {
      setLoading(() => true);
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + parameters.resource['@id'],
          parameters.values,
          {
            headers: {
              'Content-Type': 'application/ld+json',
            },
          }
        );
        return response.data;
      } catch (e) {
        if (e instanceof Error) {
          throwSubmissionError(e);
        }
        throw e;
      }
    },
    onSuccess: (resource) => {
      if (!(options?.noInvalidate === true)) {
        queryClient.invalidateQueries({
          queryKey: [buildQueryKeys(resource['@id'])[0]],
        });
      }
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const update = (resource: IResource, values: Partial<IResource>) => {
    mutate({ resource, values });
  };

  return { loading, update, error, updated };
};

export default useUpdate;
