import Form from './Form';
import ErrorAlert from 'components/alert/ErrorAlert';
import useUpdate from 'hooks/useUpdate';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { PageCard } from 'components/card/PageCard';
import { useEffect } from 'react';

interface UpdateProps {
  close?: () => void;
  retrieved: ContentTheme;
}

const Update = ({ close, retrieved }: UpdateProps) => {
  const {
    updated,
    update,
    loading: updateLoading,
    error: updateError,
  } = useUpdate<ContentTheme>();

  useEffect(() => {
    if (updated) {
      close();
    }
  }, [updated, close]);

  return (
    <PageCard>
      <Header text="Update Content Theme" />

      {updateError && <ErrorAlert message={updateError.message} />}

      <Form
        onSubmit={(values) => {
          update(retrieved, values);
        }}
        error={updateError}
        label="Update"
        loading={updateLoading}
        initialValues={retrieved}
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" onClick={close} />
        }
      />
    </PageCard>
  );
};

export default Update;
