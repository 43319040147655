import axios from 'axios';
import IResponseError from 'Type/IResponseError';

const restorePrompt = async (
  id: number,
  onSuccess: () => void,
  onError: (response: IResponseError) => void
) => {
  try {
    const axiosResponse = await axios.post(
      process.env.REACT_APP_API_URL +
        `/businesses/business_prompts/${id}/restore`
    );
    if (axiosResponse.status < 200 || axiosResponse.status >= 300) {
      throw new Error();
    }
    onSuccess();
  } catch (error) {
    onError({ error: 'Unable to restore the prompt!' });
  }
};

export default restorePrompt;
